/* Form Inputs
---------------------------------------------*/
$inputFields: "input[type='text'], input[type='password'], input[type='email'], input[type='search'], input[type='number'], input[type='tel'], input[type='url'], input[type='date'], textarea";

#{$inputFields},
select,
textarea {
  background: $input-background-default;
  box-sizing: border-box;
  @include inputDefault;
}

.inlineInput {
  @include inputDefault;

  &.blank {
    background: $black03;
    font-style: italic;
  }
}

textarea {
  @include inputDefault($min-height: $text-area-min-height-default);
  resize: vertical;
  line-height: 22px;

  &.blank {
    background: $black03;
  }
}

textarea {
  padding: 8px;
}

/* Form Fields
---------------------------------------------*/
form {
  @include clearfix;

  button,
  input[type="submit"] {
    cursor: pointer;
  }

  > div {
    margin: 0 0 20px 0;

    label {
      display: block;
    }
  }

  > button,
  > input {
    float: right;
  }
}

input[type='checkbox'] {
  margin-right: 1rem;
  vertical-align: 1px;
  margin-bottom: 1rem;
}

// Error states
span.error {
  display: block;
  color: $orange;
  font-weight: 700;
  margin-top: .5rem;
}

// Form Pages
span.blank {
  font-weight: normal;
  font-style: italic;
  background-color: $black03;
}

div.login-form {
  max-width: $form-max-width;
  width: 80%;
  margin: 0 auto;
  padding-top: 6rem;
  padding-bottom: 30px;
}

// Login form
.loginForm {

  label {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .02rem;
  }

  .loginFormHeader {
    @include chartTitle;
    font-size: 22px;
    line-height: 1.2;
  }

  button {
    cursor: pointer;
  }
}

.passwordResetLink {
  float: left;
  margin-top: 10px;
  display: inline-block;
  text-align: left;
  color: $grey;
  font-size: 14px;

  &:hover {
    opacity: .85;
  }
}

.formTitle {
  @include chartTitle;
  text-transform: uppercase;
}

.formWrapper {
  width: 100%;
}

.accountPage .content {
  max-width: $form-max-width;
  margin: auto;
  padding: 14rem 2rem;

  > h1 {
    @include chartTitle;
    text-transform: uppercase;
  }

	> h2 {
		margin-bottom: 2rem;
	}

	label {
		line-height: 2;
	}

  .errornote {
    text-align: center;
    color: $red;
    background-color: transparentize($red, .87);
    border: 2px solid transparentize($red, .5);
    padding: 1.5rem .5rem;
    border-radius: 3px;
    display: block;
    font-weight: 700;
  }

  .errorlist {
    padding-left: 0;

    li {
      list-style: none;
      color: $red;
      font-weight: 700;
    }
  }

  fieldset {
    border: none;
    padding: 0;
    padding-bottom: 2rem;

  }

  .form-row {
    margin-bottom: 2rem;
  }

  .form-check {
    display: flex;

    input {
      flex: 0 0 20px;
      margin: 3px 5px 0 0;
      max-width: 20px;
    }

    label {
      line-height: 1.5;
    }
  }

  input[type="submit"] {
    text-transform: uppercase;
    cursor: pointer;
  }
}

// Password reset link
.passwordResetSuccess {
  text-align: center;
}

.formInner {
  max-width: $form-max-width;
}

.metadataForm {
  padding: 2rem;
  margin: auto;

  > * {
    max-width: $form-max-width;
    margin-left: auto;
    margin-right: auto;
  }

  > div:last-of-type {
    // Create / Cancel Buttons
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;

    button[type="submit"] {
      margin-left: 2rem;
    }
  }
}

.helpText {
  font-style: italic;
  font-family: $font-secondary;
}
