.chartCaption {
  padding: 1rem;
  display: block;
  text-align: center;
  font-family: $font-secondary;
  font-weight: 400;
  font-style: italic;
  margin: 0 auto;
  max-width: $max-header-width;
  white-space: pre-wrap;
  margin-bottom: 4rem;
  line-height: 1.4;
  border: 1px solid transparent;

  &.chartCaptionEditable {
    font-weight: 400;
    max-width: $max-header-width;
    text-align: center;
    border: 1px solid $input-border-default;
    min-height: $text-area-min-height-default;

    &:focus {
      border-color: $teal;
    }
  }
}
