/* Document Settings
------------------------------------------------------*/
html,
body {
  background: transparent;
}


::selection {
  background: $black15;
}

html {
  font-size: 62.5%;
}

body {
  background: white;
  font-family: $font-default;
  color: $grey;
  line-height: 1.4;
  font-size: 1.4rem;
  overflow-x: hidden;

  * {
    box-sizing: border-box;
  }
}