/* Modal
------------------------------------------------------*/
#root > .modalOpen {
  height: 100vh;
  overflow: hidden;
}

#root > .modalOpen > .content > div:not(.modal),
#root > .modalOpen > nav {
  pointer-events: none;
}

.modal {
  background: $black60;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: table;
  pointer-events: none;
  z-index: 9999;

  .modalInner {
    box-shadow: black 0 0 15px;
    display: table-cell;
    vertical-align: middle;
    pointer-events: none;

    .modalContent {
      background: $white;
      font-size: 16px;
      padding: 25px 25px 25px 25px;
      width: 90%;
      max-width: 600px;
      min-width: 300px;
      height: auto;
      /* To center horizontally */
      margin: 0 auto;
      pointer-events: all;
      position: relative;

      button.close {
        position: absolute;
        top: 0;
        right: 0;
        width: 34px;
        height: 34px;
        border-width: 10px;
        border-radius: 0;
      }
    }
  }

  .actions {
    background: $greylight;
    border-top: 2px solid $gold;
    display: flex;
    position: relative;
    padding: 0;
    bottom: -25px;
    left: -25px;
    width: calc(100% + 50px);

    button {
      flex: 1;
      border-radius: 0;
      border: 0;
      padding: 15px;
    }
  }
}
