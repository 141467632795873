@import "../css/_variables";
@import "../css/_mixins";
@import "../css/_animation";

// Status and Trend Indicator

.statusAndTrendIndicator {


  &.status-null {
    border: 0;
    box-shadow: none;
    background-color: transparent;
    background-image: url('../image/status-arrows/status-arrow-null.svg');
    background-size: 32px;
    background-position: 50%;
    background-repeat: no-repeat;
  }

  /* Green Up */
  &.status-1-1,
  span.status-1-1  {
    background-image: url('../image/status-arrows/green-up.svg');
  }

  /* Green Flat */
  &.status-1-2,
  span.status-1-2 {
    background-image: url('../image/status-arrows/green-flat.svg');
  }


  /* Green Down */
  &.status-1-3,
  span.status-1-3 {
    background-image: url('../image/status-arrows/green-down.svg');
  }

  /* Yellow Up */
  &.status-2-1,
  span.status-2-1 {
    background-image: url('../image/status-arrows/yellow-up.svg');
  }

  /* Yellow Flat*/
  &.status-2-2,
  span.status-2-2  {
    background-image: url('../image/status-arrows/yellow-flat.svg');
  }

  /* Yellow Down */
  &.status-2-3,
  span.status-2-3 {
    background-image: url('../image/status-arrows/yellow-down.svg');
  }

  /* Red Up */
  &.status-3-1,
  span.status-3-1 {
    background-image: url('../image/status-arrows/red-up.svg');
  }

  /* Red Flat */
  &.status-3-2,
  span.status-3-2 {
    background-image: url('../image/status-arrows/red-flat.svg');
  }

  /* Red Down*/
  &.status-3-3,
  span.status-3-3 {
    background-image: url('../image/status-arrows/red-down.svg');
  }

  &:after {
    border-radius: 50%;
    display: block;
    overflow: hidden;
  }

  .editor &.statusAndTrendIndicator:before {
    border: 70px solid white;
    left: calc(50% - 100px);
    top: calc(50% - 100px)
  }

  /* Radial Menu Selectors
  -------------------------------------------------------*/
  span {
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    border: 2px solid white;
    position: absolute;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &.statusIndicator:after {
    display: none;
  }

  &.statusIndicator.statusAndTrendIndicator.open {
    z-index: 100;

    animation-name: shrink-grow;
    animation-duration: .2s;

    span {
      animation-name: scale-in;
      animation-duration: .2s;
    }
  }

  &.statusIndicator:before {
    background-position: 50% 50%;
    background-size: contain;
    box-shadow:  0px 0px 55px $greymedium, inset 0 0 14px $black20, inset 0 0 20px 5px $white;
  }
  /* Green Up */
  span.status-1-1 {
    top: -171.875%;
    left: 125%;
  }

  /* Green Flat */
  span.status-1-2 {
    top: -66.625%;
    left: 201.25%;
  }

  /* Green Down */
  span.status-1-3 {
    top: 66.625%;
    left: 201.25%;
  }

  /* Yellow up */
  span.status-2-1 {
    top: 171.875%;
    left: 125%;
  }

  /* Yellow Flat */
  span.status-2-2 {
    top: 212.5%;
    left: 0;
  }

  /* Yellow Down */
  span.status-2-3 {
    top: 171.875%;
    left: -125%;
  }

  /* Red Up */
  span.status-3-1 {
    top: 66.625%;
    left: -201.25%;
  }

  /* Red Flat */
  span.status-3-2 {
    top: -66.625%;
    left: -201.25%;
  }

  /* Red Down */
  span.status-3-3 {
    top: -171.875%;
    left: -125%;
  }

  &.open span {

    &:hover {
      opacity: .5;
    }
  }
}

/* Null */
.statusIndicator.statusAndTrendIndicator span.status-null {
  top: -212.5%;
  background-image: url('../image/status-arrows/status-arrow-null.svg');
}

.statusIndicator.statusAndTrendIndicator.open span.status-null {
  border: 0;
  background-color: transparent;
  box-shadow: none;
}

.statusIndicator.statusAndTrendIndicator span.status-null.active {
  border: 2px solid $black50;
  background: #aaa;
}
