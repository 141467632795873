/** Proxima Nova **/
@font-face {
    font-family: 'Proxima Nova';
    src: url('fonts/Mark Simonson - Proxima Nova Alt Bold-webfont.eot');
    src: url('fonts/Mark Simonson - Proxima Nova Alt Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/Mark Simonson - Proxima Nova Alt Bold-webfont.woff2') format('woff2'),
         url('fonts/Mark Simonson - Proxima Nova Alt Bold-webfont.woff') format('woff'),
         url('fonts/Mark Simonson - Proxima Nova Alt Bold-webfont.ttf') format('truetype'),
         url('fonts/Mark Simonson - Proxima Nova Alt Bold-webfont.svg#proxima_nova_altbold') format('svg');
    font-weight: 700;
    font-style: normal;

}

@font-face {
    font-family: 'Proxima Nova';
    src: url('fonts/Mark Simonson - Proxima Nova Alt Regular-webfont.eot');
    src: url('fonts/Mark Simonson - Proxima Nova Alt Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/Mark Simonson - Proxima Nova Alt Regular-webfont.woff') format('woff'),
         url('fonts/Mark Simonson - Proxima Nova Alt Regular-webfont.ttf') format('truetype'),
         url('fonts/Mark Simonson - Proxima Nova Alt Regular-webfont.svg#proxima_nova_altregular') format('svg');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'Proxima Nova';
    src: url('fonts/Mark Simonson - Proxima Nova Semibold-webfont.eot');
    src: url('fonts/Mark Simonson - Proxima Nova Semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/Mark Simonson - Proxima Nova Semibold-webfont.woff2') format('woff2'),
         url('fonts/Mark Simonson - Proxima Nova Semibold-webfont.ttf') format('truetype'),
         url('fonts/Mark Simonson - Proxima Nova Semibold-webfont.svg#proxima_nova_ltsemibold') format('svg');
    font-weight: 600;
    font-style: normal;

}

@font-face {
    font-family: 'Proxima Nova';
    src: url('fonts/Mark Simonson - Proxima Nova Thin-webfont.eot');
    src: url('fonts/Mark Simonson - Proxima Nova Thin-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/Mark Simonson - Proxima Nova Thin-webfont.woff2') format('woff2'),
         url('fonts/Mark Simonson - Proxima Nova Thin-webfont.woff') format('woff'),
         url('fonts/Mark Simonson - Proxima Nova Thin-webfont.ttf') format('truetype'),
         url('fonts/Mark Simonson - Proxima Nova Thin-webfont.svg#proxima_nova_ltthin') format('svg');
    font-weight: 200;
    font-style: normal;
}

/** Berling **/

@font-face {
  font-family: 'Cardo';
  src: url('fonts/cardo-regular.eot'),
       url('fonts/cardo-regular.woff2') format('woff2'),
       url('fonts/cardo-regular.woff') format('woff'),
       url('fonts/cardo-regular.ttf') format('truetype'),
       url('fonts/cardo-regular.svg#cardoregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cardo';
  src: url('fonts/cardo-bold.eot'),
       url('fonts/cardo-bold.woff2') format('woff2'),
       url('fonts/cardo-bold.woff') format('woff'),
       url('fonts/cardo-bold.ttf') format('truetype'),
       url('fonts/cardo-bold.svg#cardobold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Cardo';
  src: url('fonts/cardo-italic.eot'),
       url('fonts/cardo-italic.woff2') format('woff2'),
       url('fonts/cardo-italic.woff') format('woff'),
       url('fonts/cardo-italic.ttf') format('truetype'),
       url('fonts/cardo-italic.svg#cardoitalic') format('svg');
  font-weight: normal;
  font-style: italic;
}

/** Berling 

@font-face {
  font-family: 'Berling';
  src: url('fonts/BerlingRoman.eot'),
       url('fonts/BerlingRoman.woff2') format('woff2'),
       url('fonts/BerlingRoman.woff') format('woff'),
       url('fonts/BerlingRoman.ttf') format('truetype'),
       url('fonts/BerlingRoman.svg#BerlingRoman') format('svg'),
       url('fonts/BerlingRoman.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Berling';
  src: url('fonts/BerlingBold.eot'),
       url('fonts/BerlingBold.woff2') format('woff2'),
       url('fonts/Berling-Bold.woff') format('woff'),
       url('fonts/Berling-Bold.ttf') format('truetype'),
       url('fonts/Berling-Bold.svg#Berling-Bold') format('svg'),
       url('fonts/BerlingBold.eot?#iefix') format('embedded-opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Berling';
  src: url('fonts/BerlingItalic.eot'),
       url('fonts/BerlingItalic.woff2') format('woff2'),
       url('fonts/Berling-Italic.woff') format('woff'),
       url('fonts/Berling-Italic.ttf') format('truetype'),
       url('fonts/Berling-Italic.svg#Berling-Italic') format('svg'),
       url('fonts/BerlingItalic.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Berling';
  src: url('fonts/BerlingBold_Italic.eot'),
       url('fonts/BerlingBold_Italic.woff2') format('woff2'),
       url('fonts/Berling-BoldItalic.woff') format('woff'),
       url('fonts/Berling-BoldItalic.ttf') format('truetype'),
       url('fonts/Berling-BoldItalic.svg#Berling-BoldItalic') format('svg'),
       url('fonts/BerlingBold_Italic.eot?#iefix') format('embedded-opentype');
  font-weight: bold;
  font-style: italic;
}
**/
