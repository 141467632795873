.playbookChart {
  // Individual chart item
  background: white;
  border-bottom: 2px solid $gold;
  position: relative;
  padding: 0;
  transition: transform .2s ease-out;

  // Intro Chart Placeholder
  &.locked {
    padding: 30px 30px;

    @include large {
      padding: 30px 20px;
    }

    @media screen {
      + .playbookChart .locked {
        border-top: 2px solid $gold;
      }
    }
  }

  @include print {
    .isSafari & {
      display: inline-block !important;
      width: 100% !important;
      vertical-align: top;
    }

    .scrollMarker + & {
      display: none !important;
    }
  }

  .chart {
    // Chart inner wrapper
    position: relative;
    margin: 0 auto;
    max-width: $global-content-width;
    padding: 30px 0;

    .editor & {
      max-width: $global-content-width-editor;
    }

    @include large {
      padding: 70px 60px;
    }

    &.coverPage {
      display: block;

      h1 {
        font-size: 4rem;
        padding: 4rem 0;
        margin-top: 200px;
        border-bottom: 4px solid $gold;
        border-top: 4px solid $gold;

        @include small-down {
          margin-top: 75px;
        }

        &:before {
          top: auto;
          bottom: -135px;
        }

        &:after {
          border-left: 2px solid $gold;
          width: 0;
          height: 130px;
          top: auto;
          bottom: -130px;
          left: calc(50% - 2px);
        }
      }

      img {
        max-width: 150px;
        margin: 150px auto 0 auto;
        display: block;
      }
    }

    &.stratopProcess,
    &.lifeplanProcess {
      display: block;

      img {
        margin-top: 10rem;
      }
    }

    &.understandingPerspective {
      display: block;

      h2, p, img {
        margin: 0 auto 15px auto;
        text-align: center;
        max-width: 600px;
        width: 80%;
      }

      h2 {
        margin-top: 60px;
        margin-bottom: 5px;
        font-size: 18px;
      }

      img {
        margin-bottom: 0;
      }
    }
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  > .delete {
    position: absolute;
    top: 67px;
    right: 20px;
    z-index: 2;

    &:hover {
      border-color: $red;
    }
  }

  .viewer & {
    padding: 30px 8px;
    border-bottom: 0;
    page-break-before: always;

    @include medium {
      padding: 50px 25px;
      border: 0;
    }
  }
}

.scrollMarker {
  opacity: 0;
  visibility: hidden;
  margin: 0;
  padding: 0;
  height: 0;

  @include print {
    display: none;
  }
}
