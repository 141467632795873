@import "../../css/_variables";
@import "../../css/_mixins";
@import "TableOverflowWrapper";

.genericTableWrap {
  // Remove the extra whitespace added on the first/last columns via border-spacing
  margin-left: -10px;
  margin-right: -10px;
}

.genericTable {
  border-spacing: 10px 0;
  border-collapse: separate;
  table-layout: initial;
  max-width: 100%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: block;
  margin: auto;
  overflow-x: auto;

  @include print {
    page-break-inside: auto;
  }
}

/* UI Controls : Button Cells (Remove Background) */
.genericTable tr td.actions {
  background: transparent;

  button {
    margin-left: 3px;
  }
}

.genericTable td {
  height: 100%;

  @include print {
    page-break-inside: auto;
    vertical-align: top;

    ul {
      display: inline-block;
      padding: 0;

      li {
        display: inline-block;
        position: relative;
        padding-left: 20px;

        &:before {
          content: "";
          width: 0;
          height: 0;
          border-radius: 8px;
          border: 2px solid black;
          position: absolute;
          top: 12px;
          left: 5px;
        }
      }
    }

    ul, div {
      page-break-inside: auto;
    }
  }
}

/* UI Controls : Button Cells (Remove Border) */
.genericTable tr td:first-of-type,
.genericTable tr.actions td:last-child:not(:nth-of-type(2)) {
  border: 0;
  width: 25px;
}

.genericTable tr:last-of-type td {
  border: 0;
  min-width: 25px;
}

/* UI Controls : Header Row */
.genericTable tr td:first-of-type {
  text-align: right;
  width: 90px;
  white-space: nowrap;
}

/* UI Controls : Action Row */
.genericTable tr.actions td {
  text-align: center;
  padding-top: 0;
  white-space: nowrap;
}

/* UI Controls : Buttons */
.genericTable button {
  border-radius: 3px;
  height: 30px;
  padding: 0 5px;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }
}

.genericTable button.add {
  border: 1px solid $blue;
  color: $blue;

  &:hover {
    border: 1px solid $blue;
    background-color: transparentize($blue, .75);
  }
}

.genericTable button.delete {
  height: 30px;
  width: 30px;
  padding: 0;
}

.genericTable button.delete {
  width: 30px;
  padding: 0;
}

.genericTable button.highlight,
.genericTable button.number {
  border-color: transparent;
  padding: 0;
  width: 30px;
}

.genericTable button.highlight:hover,
.genericTable button.highlight:focus,
.genericTable button.number:hover,
.genericTable button.number:focus {
  border-color: $teal25;
  background-color: $teal10;
}

.genericTable button.highlight {
  background: white url('../../image/icon-highlight.svg') no-repeat center;
  background-size: contain;
}

.genericTable button.number {
  background: white url('../../image/icon-list.svg') no-repeat center;
  background-size: contain;
}


/* Add Row */
.genericTable tr.add td {
  padding-top: 8px;
  text-align: center;
}


/* Thead Header Column Status */
.genericTable.headerColumn tr:not(.actions) td:nth-child(2) {
  background-color: $greylightwarm;
}

.genericTable.headerColumn tr:not(:last-of-type) td:nth-child(2)  {
  border-bottom: 2px solid $gold;
}

.genericTable.headerColumn tr.actions button.highlight {
  border-color: $teal;
  background-color: $teal25;
}


/* Tlist Status */
.genericTable.isNumbered tr:not(.actions) td:nth-child(2) {
  background-color: $greylightwarm;
}
.genericTable tr.actions td  {
  border-bottom: 0;
}
.genericTable.isNumbered tr.actions + tr td:not(:first-child):not(:last-child)  {
  border-top: 2px solid $gold;
}
.genericTable.isNumbered tr.actions + tr td.numbers ~ td  {
  border-top: 2px solid $greymedium;
}
.genericTable.isNumbered tr.actions + tr.highlighted td:not(:first-child):not(:last-child)  {
  border-top: 2px solid $gold;
}
.genericTable.isNumbered tr.actions + tr td.numbers  {
  border-top: 2px solid $gold;
}
.genericTable.isNumbered tr.actions button.number {
  border-color: $teal;
  background-color: $teal25;
}
.genericTable tr.actions + tr td.highlighted {
  border-top: 2px solid $gold;
}


/* Thead Header Row Status */
.genericTable.headerRow tr:nth-child(1) td:nth-child(n+2):not(:last-child) {
  border-bottom: 2px solid $gold;
}
.genericTable.headerRow tr:nth-child(2) td:nth-child(n+2) {
  background-color: $greylightwarm;
  border-bottom: 2px solid $gold;
}

.genericTable.headerRow tr td:first-of-type button.highlight {
  border-color: $teal;
  background-color: rgba(0,143,167,0.25);
}

/* Thead Header Row AND Numbered Status */
.genericTable.isNumbered tr td.numbers {
  border-bottom: 2px solid $gold;
  background-color: $greylightwarm;
  text-align: center;
  font-family: $font-primary;
  font-weight: 600;
  font-size: 24px;
  width: 25px;
}

/* Viewer */
.viewer .genericTable tr td,
.viewer .genericTable tr td:first-of-type {
  width: auto;
  text-align: left;
  white-space: normal;
}

.viewer .genericTable tr:first-of-type td {
  border-top: 1px solid $greymedium;
}

.viewer .genericTable tr.highlighted td {
  border-top: 2px solid $gold;
  border-bottom: 2px solid $gold;
}

.viewer .genericTable tr td {
  border-bottom: 1px solid $greymedium;

  @include print {
    font-size: 12px;
  }
}

/* Viewer - Header Row */
.viewer .genericTable.headerRow tr:first-child td {
  background-color: $greylightwarm;
  border-bottom: 2px solid $gold;
  border-top: 2px solid $gold;
}

.viewer .genericTable.headerRow tr:nth-child(2) td:nth-child(n+2) {
  background: $greylight;
  border-bottom: 1px solid $greymedium;
}

/* Viewer - Header Column / List */
.viewer .genericTable.isNumbered tr td:first-of-type,
.viewer .genericTable.headerColumn tr td:first-of-type {
  background-color: $greylightwarm;
  border-bottom: 2px solid $gold;
}

.viewer .genericTable tr:first-of-type td.highlighted,
.viewer .genericTable.isNumbered tr:first-of-type td:first-of-type,
.viewer .genericTable.isNumbered tr:first-of-type td.highlighted,
.viewer .genericTable.headerColumn tr:first-of-type td:first-of-type,
.viewer .genericTable.headerColumn.headerRow tr:first-of-type td:nth-child(2) {
  background-color: $greylightwarm;
  border-top: 2px solid $gold;
}

/* Viewer - Highlighted Header Row */
.viewer .genericTable.isNumbered tr:first-of-type td {
  vertical-align: middle;
}

.viewer .genericTable.isNumbered tr:first-of-type.highlighted td:nth-child(2) {
  border-bottom: 2px solid $gold;
}

.viewer .genericTable.isNumbered tr td:nth-child(2),
.viewer .genericTable.headerColumn tr td:nth-child(2) {
  background: $greylight;
  border-bottom: 1px solid $greymedium;
}

.highlighted + .highlighted > td {
  border-top: 0 !important;
}


.viewer .genericTable.isNumbered tr.highlighted td:nth-child(2),
.viewer .genericTable.headerColumn tr.highlighted td:nth-child(2) {
  border-bottom: 2px solid $gold;
}

.viewer .genericTable.isNumbered tr td.highlighted:nth-child(2) {
  background-color: $greylightwarm;
}

.viewer .genericTable.isNumbered tr.highlighted td:nth-child(2),
.viewer .genericTable.headerColumn tr.highlighted td:nth-child(2) {
  background: $greylightwarm;
}

.viewer .genericTable.isNumbered tr td.numbers {
  width: 90px;
  text-align: center;
}

/* Highlight Styles */
.genericTable td.highlighted,
.genericTable tr.highlighted td {
  background: $greylightwarm;
  border-color: $gold !important;
}

.genericTable td {
  border-color: rgba(0,0,0,.15);
}

.genericTable tr.highlighted td:first-of-type {
  background: none;
}

.viewer .genericTable tr.highlighted td:first-of-type {
  background: $greylightwarm;
}

.genericTable tr td.highlighted:first-of-type {
  background: $greylightwarm;
}

ul.charts > li td h1,
ul.viewer > li td h1,
ul.charts > li td h2
ul.viewer > li td h2 {
  display: inline;
  line-height: 1.2;
  letter-spacing: 0;
  padding: 0;
  margin-bottom: 0;
  color: currentColor;
}

ul.charts > li td h1,
.genericTable.chart.editableHeaders h1 {
  font-weight: bold;
  text-transform: initial;
  font-family: $font-secondary;
  font-size: inherit;
  font-style: italic;
  margin: 0;
}

.genericTable td h1,
.viewer .genericTable h1 {
  font-size: inherit;
  margin-bottom: 0;
  padding: 0 !important;
}

.genericTable td h2,
.viewer .genericTable h2 {
  padding: 0 !important;
  margin: 0 !important;
  text-transform: uppercase;
  line-height: 1.2;
  letter-spacing: .05rem;
}

.viewer .genericTable.fixedLayout {
  table-layout: fixed;

  tr td,
  tr.highlighted td,
  tr.highlighted td:first-of-type,
  &.isNumbered tr td.numbers,
  &.headerColumn tr td:first-of-type {
    width: 1%;
  }
}

.fixedLayoutToggleWrap {
  max-width: $max-header-width;
  margin: 0 auto 3rem auto;
  padding: 1rem;
  background-color: lighten($goldlight, 15%);
}
