@import "../css/_variables";

.pagination {
  width: 90%;
  max-width: 600px;
  margin: 3rem auto 1rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.5rem;
  gap: 1rem;
  background: $black05;
  border-radius: 1.5rem;
}