@import "../../css/_variables";
@import "../../css/_mixins";

.fourHelpfulListsWrapper {
  display: inline-block;
  page-break-inside: avoid;
  width: 100%;
}

.fourHelpfulLists table {
  margin-bottom: 80px;
  border-collapse: separate;
  border-spacing: 8px 0;
  margin-left: -8px;
  margin-right: -8px;
  table-layout: initial;

  @include print {
    margin-bottom: .25in;
  }
}

.fourHelpfulLists td {
  background: white;
  vertical-align: middle;
}

.fourHelpfulLists td input {
  padding-left: 4px;
  height: 32px;
  border: 1px solid $greymedium;
}

.fourHelpfulLists td div {
  display: flex;
  align-items: center;
}

.fourHelpfulLists td div span {
  padding: 0 4px;
}

.fourHelpfulLists tr th {
  border-top: 2px solid currentColor;
  border-bottom: 2px solid currentColor;
  font-family: $font-primary;
  font-weight: 600;
}

.fourHelpfulLists th {
  color: inherit;
}

.fourHelpfulLists td {
  padding: 4px 0;

  @include print {
    padding: 0;
  }
}

/* Add Status Row*/
.fourHelpfulLists tr.add td {
  border-top: 2px solid $gold;
  border-bottom: 2px solid $gold;
}

.editor .fourHelpfulLists tr:nth-last-child(2) td {
  border-bottom: 0;
}

/* Status + Trend Indicator */
.fourHelpfulLists .statusAndTrendIndicator {
  position: relative;
  display: block;
  margin: 10px auto;

  @include print {
    margin: 2px auto;
  }
}

.fourHelpfulLists th:first-of-type {
  width: 51%;

  @include print {
    padding-left: 0;
  }
}

.fourHelpfulLists th:nth-of-type(2) {
  text-align: center;
}

/* Optimize */
.fourHelpfulLists .optimize th {
  background: #ccece3;
  color: $green;
}

/* Change */
.fourHelpfulLists .change th {
  background: #F3E5ED;
  color: #8e0f58;
}

/* Clarify */
.fourHelpfulLists .clarify th {
  background: #CFD4E4;
  color: $blue;
}

/* Add */
.fourHelpfulLists .add th {
  background: #CCE9ED;
  color: $teal;
}

/* Controllability Toggle */
.fourHelpfulLists .controllabilityToggle,
.fourHelpfulLists .controllabilityToggle:hover {
  background: none;
  padding: 8px;
  border: 0;
  box-shadow: none;
  cursor: pointer;
}

.controllability-header {
  text-align: center;
}

.controllability {
  text-transform: uppercase;
  letter-spacing: .7px;
  height: 32px;
  display: block;
  text-align: left;
  font-weight: 700;
  width: 110px;
  position: relative;
  padding-left: 38px;

  @include print {
    height: 22px;
    font-size: 12px;
  }
}

.viewer .controllability {
  margin: auto;
  position: relative;
  left: 15px;
  line-height: 2;
}

.editor .controllability:hover {
  opacity: .8;
}

.controllabilityToggle {
  margin: auto;
  display: block;
}

.controllabilityToggle:focus .controllability {
  text-decoration: underline;
}

.controllability:before {
  vertical-align: middle;
  margin-right: .75rem;
  display: inline-block;
  position: absolute;
  left: 0;

  @include print {
    top: -2px;
  }
}

.controllability-full {
  color: $green;
}

.controllability-full:before {
  width: 24px;
  content: url('../../image/fill-diamonds/diamond-green-full.svg');
}

.controllability-partial {
  color: $yellow;
}

.controllability-partial:before {
  width: 24px;
  content: url('../../image/fill-diamonds/diamond-orange-partial.svg');
}

.controllability-none {
  color: $orange;
}

.controllability-none:before {
  width: 24px;
  content: url('../../image/fill-diamonds/diamond-red-none.svg');
}

.fourHelpfulLists .columnActions {
  margin: 1.5rem;
  display: flex;
  gap: 1.0rem;
  align-items: center;
  justify-content: flex-end;

  button {
    min-width: 23.25%;

    + button {
      min-width: 25.5%;
    }
  }
}
