.tableOverflowWrapper {
  position: relative;
  padding: 0 60px;
  overflow: auto;
  width: 100%;
  max-width: calc(100vw - 180px);

  @media (min-width: 1024px) {
    width: calc(100% + 120px);
    left: -60px;
  }

  > table {
    min-width: 1200px;
  }
}