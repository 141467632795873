/* Body
------------------------------------------------------*/
.content {
  padding: 0;
  position: relative;
  min-height: 100vh;

	@include print {
		min-height: 0;
	}

  // Dashboard
  p.newPlaybooks {
    margin-bottom: 25px;
    margin-top: 0;
    text-align: center;

    a.button {
      margin: 10px 15px;
      position: relative;

      &:before {
        content: '';
        border-radius: 10px;
        background: $gold;
        width: 10px;
        height: 10px;
        position: absolute;
        left: calc(50% - 5px);
        top: -25px;
      }

      &:after {
        content: '';
        background: $gold;
        width: 2px;
        height: 40px;
        position: absolute;
        left: calc(50% - 1px);
        top: -60px;
      }
    }
  }

  // Error Pages
  div.error {
    display: block;
    width: 80%;
    margin: 100px auto;
    max-width: 600px;

    h1 {
      color: $gold;
      line-height: 1.4;
      font-size: 28px;
      font-weight: 700;
      position: relative;
      letter-spacing: 2px;
      text-align: center;
      text-transform: uppercase;
    }
  }
}

button.backToTop {
  box-shadow: rgba(black,0.2) 0px 2px 5px;
  background: white;
  cursor: pointer;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 100;
  font-size: 12px;
  padding: 3px 8px;

  &:hover, &:focus, &:active {
    background: rgba($blue,0.25);
  }

  @include print {
    display: none !important;
  }
}

.homeWrapper {
  padding-top: $meta-height;
}

.sr-only {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
		clip-path: inset(50%) !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}