/* Plan Title / Save Row
------------------------------------------------------*/
.playbookMeta {
  display: flex;
  flex-wrap: wrap;
  background: white;
  border-bottom: 2px solid $gold;
  margin: 0;
  position: fixed;
  top: 48px;
  left: 0;
  width: 100%;
  padding: 10px;
  z-index: 90;
  height: 96px;

  @include medium {
    display: block;
    height: 48px;
  }

  a, button {
    margin-left: 5px;
    flex: 1 1 auto;

    @include medium {
      float: right;
    }

    &.edit { order: 2; }
    &.share { order: 3; }
    &.view { order: 4; }
    &.undo { order: 5; }
    &.redo { order: 6; }
    &.save { order: 7; }
  }

  &.viewerActive {
    height: 48px;
    padding: 6px 0;
    position: fixed;
    top: 0;
    left: auto;
    right: 278px;
    z-index: 100;
    border: 0;
    background: transparent;
    text-align: right;
    width: calc(100% - 350px); /* Leave gaps for logo & logout links */

    @include medium-down {
      right: 170px;
      display: block;
      left: auto;
      top: -1px;
    }

    @include print {
      display: none;
    }

    a {
      @include button;
      color: white;
      float: right;
      border: 0;
      text-transform: none;
      height: auto;

      &:hover {
        box-shadow: transparent 0 0 0;
        background: transparent;
        text-decoration: underline;
        border: 0;
      }

      &.edit {
        position: relative;
        line-height: 20px;

        @include medium-down {
          white-space: nowrap;
          position: static;
          left: auto;
          bottom: auto;
          top: 5px;
          right: -70px;
          line-height: 40px;
        }

        &:after {
          content: '|';
          font-weight: 400;
          display: inline-block;
          margin-left: 1rem;

          @include medium-down {
            margin-left: 6px;
          }
        }
      }
    }
  }

  .title {
    display: inline-block;
    font-weight: 700;
    line-height: 26px;
    font-size: 14px;
    margin-right: auto;
    width: 100%;

    @include medium {
      font-size: 18px;
      width: auto;
    }
  }

  a {
    @include button('outline', 'teal');
    float: right;
    text-transform: uppercase;
    vertical-align: top;
    height: 24px;
    font-size: 11px;
    line-height: 18px;
    margin-bottom: 3px;
    padding: 1px 6px;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
    text-align: center;

    svg {
      display: inline-block;
      margin-right: .4rem;
    }

    &[disabled] {
      cursor: default;
    }

    @include medium {
      @include button('outline', 'teal');
      height: 28px;
      font-size: 13px;
      padding: 3px 12px;
      margin-left: 10px;
      margin-bottom: 0px;
    }

    &.view {
      float: right;
    }
  }

  button:not(.close):not(.delete) {
    @include button('outline', 'teal');
    float: right;
    font-size: 11px;
    padding: 0px 6px;
    margin-bottom: 3px;
    text-transform: uppercase;
    height: 24px;
    cursor: pointer;

    @include medium {
      height: 28px;
      padding: 2px 6px;
      font-size: 13px;
      margin-bottom: 0px;
    }

    &.save {

      &:before {
        content: '\2713';
        display: inline-block;
        padding-right: .6rem;
        font-size: 80%;
        vertical-align: 1px;
      }

      &:not([disabled]) {
        cursor: pointer;
        background-color: lighten($green, 3%);
        border-color: $green;
        color: $white;

        &:hover {
          background-color: $green;
          border-color: darken($green, 3%);
        }

        &:before {
          content: '';
          padding-right: 0;
        }
      }

      @include medium {
        margin-left: 15px;
      }
    }

    &.redo {
      svg {
        margin-left: .5rem;
      }
    }

    &.undo {
      @include medium {
        margin-left: 15px;
      }

      svg {
        margin-right: .5rem;
      }
    }
  }

  form.metadataForm {
    box-shadow: 0 3px 3px $black20;
    background: white;
    position: fixed;
    top: 48px;
    left: 0;
    overflow: auto;
    height: calc(100% - 48px);
    width: 100%;

    > h2 {
      padding-top: 120px;
    }

    > h2,
    > div,
    > textarea,
    > fieldset {
      display: block;
      max-width: $form-max-width;
      margin-left: auto;
      margin-right: auto;
    }

    > h2 {
      font-size: 24px;
      margin-bottom: 25px;
    }

    button[type='submit'] {
      min-width: 75px;
    }

    .cancel {
      color: lighten($grey, 15%);
      border-color: currentColor;
      float: right;
      margin-right: 2rem;

      &:hover {
        color: $grey;
        background-color: $greymediumlight;
      }
    }
  }

  div.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
  }

  div.share {
    font-weight: normal;
    border: 2px solid $gold;
    box-shadow:  3px 3px 3px $black15;
    border-radius: 0 0 4px 0;
    position: absolute;
    background: white;
    padding: 15px;
    right: 150px;
    top: 46px;
    width: 500px;
    display: flex;

    @include medium-down {
      right: 10px;
			top: 94px;
    }

    > div {
      flex: 1;
      padding: 0 15px 0 0;
      text-align: center;

      + div {
        padding: 0 0 0 15px;
        border-left: 2px solid $greymediumlight;
      }
    }

    p:first-of-type {
      font-family: $font-primary;
      font-weight: 600;
      margin-bottom: 5px;
    }

    img {
      display: block;
      margin: 0 auto 10px auto;
      border-radius: 50%;
      border: 2px solid $gold;
      width: 60px;
      height: 60px;
    }

    mark {
      background: rgba($yellow,0.4);
      padding: 0 3px;
      max-height: 17px;
      display: inline-block;
    }

    button.close {
      position: absolute;
      top: 13px;
      right: 23px;
    }

    div.copyableText {
      font-weight: normal;
      margin-top: 6px;

      input {
        background: rgba($teal, .2);
        margin: 0 0 5px 0;
        padding: 4px 8px;
        text-align: left;
        width: 100%;
      }

      button {
        display: block;
        width: 100%;
      }
    }
  }
}
