@import "../../css/_variables";
@import "../../css/_mixins";

.lifePlanAccountabilityOuter {
  padding: 0 80px;
  margin: 200px auto;
  max-width: 700px;
  width: 100%;

  @include print {
    max-width: 600px;
    margin: 100px auto;
  }

  .editor & {
    margin: 300px auto;
  }
}

.lifePlanAccountabilityInner {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  overflow: visible;
  position: relative;

  .circle {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

.lifePlanAccountability .circle {
  border: 2px solid #83888f;
  display: block;
  border-radius: 50%;
}


/* Buttons */
.lifePlanAccountability .group .add {
  color: $blue;
  border: 2px solid currentColor;
  margin-top: .75rem;
}

.lifePlanAccountability .delete {
  flex: 0 0 3rem;
  margin-right: .2rem;
}

.lifePlanAccountability li span {
  line-height: 24px;
}

.lifePlanAccountability li span,
.lifePlanAccountability li input {
  flex: 1 1 100%;
}

.lifePlanAccountability li input {
  border: 1px solid transparent;
}

.lifePlanAccountability .center {
  position: relative;
  width: 150px;
  height: 150px;
  background: $gold;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  top: calc(50% - 75px);
  left: calc(50% - 75px);
  font-family: $font-primary;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .1rem;

  @include print {
    border: 2px solid $gold;
  }
}

.lifePlanAccountability .group {
  border: 2px solid $gold;
  width: 200px;
  min-height: 100px;
  padding: 1.5rem;
  box-shadow: 0 0 0 4px white;
  background: white;
  position: absolute;
  border-radius: 4px;
}

.viewer .lifePlanAccountability .group {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  flex-direction: column;
}

.lifePlanAccountability .group ul {
  list-style: none;
  display: inline;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.lifePlanAccountability .group li {
  line-height: 1;
}

.editor .lifePlanAccountability .group li {
  line-height: 1;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
}


.lifePlanAccountability .group > span,
.lifePlanAccountability .group > input {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: .05rem;
}

.editor .lifePlanAccountability .group > span {
  text-transform: uppercase;
}

/* Associate Groups*/
.viewer .lifePlanAccountability .group {
}

.viewer .lifePlanAccountability {
  text-align: center;
}

/* Group 1 (Top) */
.lifePlanAccountability .group-1 {
  top: -50px;
  left: calc(50% - 75px);
}

.viewer .lifePlanAccountability .group-1 {
  top: -75px;
  left: calc(50% - 100px);
}

/* Group 2: (Right) */
.lifePlanAccountability .group-2 {
  top: calc(50% - 75px);
  left: calc(100% - 100px);
}

.viewer .lifePlanAccountability .group-2 {
  top: calc(50% - 100px);
  left: calc(100% - 125px);
}

/* Group 3: (Bottom) */
.lifePlanAccountability .group-3 {
  top: calc(100% - 50px);
  left: calc(50% - 75px);
}

.viewer .lifePlanAccountability .group-3 {
  top: calc(100% - 125px);
  left: calc(50% - 100px);
}

/* Group 4: (Center) */
.lifePlanAccountability .group-4 {
  top: calc(50% - 75px);
  left: calc(0% - 100px);
}

.viewer .lifePlanAccountability .group-4 {
  top: calc(50% - 100px);
  left: calc(0% - 75px);
}

/* Responsive
----------------------------------------------------*/

@media screen and (max-width: 640px) {

  .viewer {
    .lifePlanAccountability {

      .lifePlanAccountabilityOuter {
        margin: 0;
      }

      .lifePlanAccountabilityInner {
        height: auto;
        position: static;
        margin: 0;
        padding-bottom: 0;
      }

      .circle {
        border: 0;
        border-radius: 0;
        position: static;
        height: auto;
      }

      .center {
        position: relative;
        top: 0;
      }

      .group {
        border-radius: 10px;
        position: relative;
        top: 0 !important;
        left: 0 !important;
        margin: 25px auto 0 auto;
        padding: 5px 15px;
        width: auto !important;
        height: auto !important;
        min-height: 45px;

        > span {
          position: static;
          display: block;
          text-align: center;
          padding: 4px 0;
        }

        ul {
          margin: 0;
        }

        &:before {
          content: "";
          background: $gold;
          width: 2px;
          height: 25px;
          position: absolute;
          top: -27px;
          left: calc(50% - 1px);
        }
      }
    }
  }
}