@import "_mixins";

table {
  display: table;
  max-width: 100%;
  width: 100%;
  border-collapse: separate;
  border-spacing: 8px 0;
  table-layout: fixed;

  thead {
    tr {
      td, th {
        background: white;
        border-top: 2px solid $gold;
        border-bottom: 2px solid $gold;
        color: $grey;
        font-weight: 700;
        padding: 10px;
        text-align: left;
        text-transform: uppercase;
        vertical-align: middle;
      }
    }
  }

  tbody,
  tfoot {
    tr {
      td, th {
        background: $black03;
        border-bottom: 2px solid $greymedium;
        font-weight: normal;
        color: $grey;
        padding: 8px;
        text-align: left;
        vertical-align: top;

        &.lifeplan {
          color: $green;
        }

        &.stratop {
          color: $blue;
        }

        a {
          display: inline-block;
          font-weight: 700;
          color: $teal;
          text-decoration: underline;

          &:hover, &:focus {
            color: lighten($teal,15%);
          }
        }
      }
    }
  }

  tr.add td {
    background: $black03 url('../image/pattern-angles.png');
    background-size: 40px;

    button:hover {
      box-shadow: none;
      cursor: pointer;
    }
  }

  tfoot {
    tr {
      td, th {
        background: white;
        border-bottom: 2px solid $gold;
      }
    }
  }

  span.level {
    text-align: center;
    font-weight: bold;
    padding: 4px;
    display: block;
    font-family: $font-primary;
    border-radius: 3px;

    &.level-0 {
      background: rgba($green, 0.2);
      color: $green;
    }

    &.level-1 {
      background: rgba($gold, 0.2);
      color: darken($gold,20%);
    }

    &.level-2 {
      background: rgba($purple, 0.2);
      color: $purple;
    }
  }

  &.home {
    @include medium-down {
      thead {
        display: none;
      }

      tbody {
        tr {
          display: block;
          margin-bottom: 15px;
          border-top: 2px solid $gold;

          td {
            display: block;
            text-align: left;
            border-bottom: 0;
            padding-left: 110px;
            padding-right: 10px;
            position: relative;

            &:before {
              content: attr(data-label);
              position: absolute;
              top: 13px;
              left: 10px;
              font-family: $font-primary;
              font-size: 12px;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}

.tableHeader {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  position: relative;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding: 15px 0;

  &:before {
    content: '';
    border-radius: 10px;
    background: $gold;
    width: 10px;
    height: 10px;
    position: absolute;
    left: calc(50% - 5px);
    bottom: -5px;
  }

  &:after {
    content: '';
    background: $gold;
    width: 2px;
    height: 20px;
    position: absolute;
    left: calc(50% - 1px);
    bottom: -20px;
  }
}
