.statusIndicatorDecorator {
  display: inline;
  position: relative;
  
  span {
    visibility: hidden;
    width: 100%;
    height: auto;
    overflow: hidden; 
    display: inline;
    position: absolute; 
  }
  
  img {
    border-radius: 50%;
    overflow: hidden;
    max-width: 33px;
    max-height: 33px;
  }
}

.playbookChart .statusIndicatorDecorator img {
  display: inline-block;
  margin: 0;
}