/* Editable Chart Labels
------------------------------------------------------*/
@include print {
  .stickyTitle {
    display: none !important;
  }
}

@media screen {
  .editor {
    .stickyTitle {
      display: none;
    }
  }
  .viewer {
    .stickyTitle {
      background: linear-gradient(90deg,#fff 0%, #eee 25%, #eee 75%, #fff 100%);
      color: #333;
      font-family: $font-primary;
      font-weight: 600;
      margin: 0 0 2px 0 !important;
      height: 30px;
      line-height: 30px;
      text-align: center;
      padding: 0 10px;
      width: 100% !important;
      max-width: none !important;
      text-transform: uppercase;

      display: block;
      position: sticky;
      top: 48px;
      z-index: 5;

      + .chartTitle, + div.chartTitleWrap, + section.editableTitleWrap {
        background: white;
        position: relative;
        margin-top: 0px;
        z-index: 10;

        &::after {
          content: "";
          width: calc(100% + 20px);
          height: 32px;
          background: white;
          position: absolute;
          top: -34px;
          left: -10px;

          @include medium {
            width: calc(100% + 56px);
            left: -28px;
          }
        }
      }
    }
  }

  .viewer {
    .chartTitleWrap,
    .editableTitleWrap {
      padding: 0 8px;
      width: calc(100% + 20px);
      transform: translate(-10px,0);

      @include medium {
        width: calc(100% + 56px);
        transform: translate(-28px,0);
      }

      .chartTitle {
        position: relative;
        top: -2px;
      }
    }
  }
}

.chartTitle,
input.chartTitle,
h1.chartTitle,
h2.chartTitle {
  @include chartTitle;
  display: block;
  margin-top: 0;
  text-align: center;
  text-transform: uppercase;
  font-family: $font-primary;
  width: 100%;
  border: 1px solid transparent;

  &.chartTitleEditable {
    margin-bottom: 3rem;
    border: 1px solid $input-border-default;
    border-radius: 2px;
    line-height: 1.4 !important;
    max-width: $max-header-width;

    &:focus {
      border-color: $teal;
    }
  }

  .locked & {
    margin: 0 auto;
    max-width: calc(#{$global-content-width-editor} - 120px);
    vertical-align: top;

    .editor & {
      max-width: calc(#{$global-content-width} - 120px);
      display: flex;
      justify-content: center;
    }
  }

  .viewer & {
    border-top: 2px solid $gold;
    border-bottom: 2px solid $gold;
    padding: 1rem 0;
    margin-bottom: 2rem;

  }
}

