// Combo box select
.Select {
  font-family: $font-default;

  .Select-control {
    background: $input-background-default;
    border: 1px solid $input-border-default;
    color: $grey;
    border-radius: 2px;
  }

  .Select-menu-outer {
    border-radius: 0;
    border-color: $grey;
  }

  &.is-focused {
    div.Select-control {
      background: white;
    }
  }
}