@import '../../css/variables',
        '../../css/mixins',
        '../widgets/Dashboard.scss';

@mixin printHeaderStyle {
  font-family: $font-primary;
  font-size: 9.5px;
  line-height: 1.0rem;
  background: $greylight;
  padding: 0.6rem 0.5rem;
}

@page {
  size: 11in 17in landscape;
  margin: 0in;
}

@-moz-document url-prefix() {
  @page {
    size: 11in 17in landscape;
    margin: 0in;
  }
}

@include print {
  html {
    print-color-adjust: exact !important; /* Firefox */
    font-size: 57.5% !important;
  }
}

nav {
  @include print {
    display: none !important;
  }
}

button.printPlanOnaPage {
  position: fixed;
  top: 6px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  background: white;
  display: flex;
  gap: 6px;
  align-items: center;
  cursor: pointer;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  &:hover, &:focus {
    background: mix(white,$goldlight);
    border-color: $gold;
  }

  &:before {
    content: "";
    background: url('data:image/svg+xml,<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.5 7C2.77614 7 3 6.77614 3 6.5C3 6.22386 2.77614 6 2.5 6C2.22386 6 2 6.22386 2 6.5C2 6.77614 2.22386 7 2.5 7Z" fill="black"/><path d="M5 0C3.89543 0 3 0.89543 3 2V4H2C0.895431 4 0 4.89543 0 6V9C0 10.1046 0.895431 11 2 11H3V12C3 13.1046 3.89543 14 5 14H11C12.1046 14 13 13.1046 13 12V11H14C15.1046 11 16 10.1046 16 9V6C16 4.89543 15.1046 4 14 4H13V2C13 0.895431 12.1046 0 11 0H5ZM4 2C4 1.44772 4.44772 1 5 1H11C11.5523 1 12 1.44772 12 2V4H4V2ZM5 7C3.89543 7 3 7.89543 3 9V10H2C1.44772 10 1 9.55228 1 9V6C1 5.44772 1.44772 5 2 5H14C14.5523 5 15 5.44772 15 6V9C15 9.55228 14.5523 10 14 10H13V9C13 7.89543 12.1046 7 11 7H5ZM12 9V12C12 12.5523 11.5523 13 11 13H5C4.44772 13 4 12.5523 4 12V9C4 8.44772 4.44772 8 5 8H11C11.5523 8 12 8.44772 12 9Z" fill="black"/></svg>');
    background-repeat: no-repeat;
    background-size: contain;
    width: 16px;
    height: 14px;
    display: block;
  }
  @include print {
    display: none !important;
  }
}

.viewer .planOnAPage {
  text-align: center;

  .planOnAPage-BareWrapper {
    color: $grey;
    display: block;
    max-height: 400px;
    overflow: hidden;

    @include medium-down {
      border: 2px solid white;
      box-shadow: rgba(black,0.3) 0 3px 10px;
      padding: 0.2in;
      max-height: none;
      max-width: 280px;
      margin: 0 auto;

      + a.button {
        background: white;
        box-shadow: white 0px 0px 10px 10px;
        position: relative;
        top: -110px;

        &:hover, &:focus {
          background: mix($blue, white, 25%);
        }
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;

      @include medium {
        display: none;
      }
    }

    &:hover, &:active {
      .planOnAPage-Wrapper {
        border-color: $blue;
      }
    }

    .planOnAPage-Wrapper {
      border: 6px solid white;
      border-radius: 6px;
      box-shadow: rgba(black,0.3) 0 10px 30px;
      text-align: left;
      padding: 0.5in;
      max-width: 18in;
      width: 18in;
      overflow: hidden;
      margin-left: 50%;
      transform: translateX(-50%) translateY(-37%) scale(0.3);

      @include medium-down {
        display: none;
      }

      .replenishmentCycle .chart,
      .valueBuildingCycle .chart {
        padding: 0;
      }
    }
  }
}

.planOnAPage-Wrapper {
  max-width: 17in;
  width: 100%;
  margin: 50px auto 0 auto;
  overflow: auto;

  @include print {
    margin-top: 0;
    max-width: 17in;
  }

  @include print {
    &.isSafari {
      max-width: 18in;

      .planOnAPage-Poster {
        width: 18in;
        height: 11.5in;
        transform: scale(0.75);
        position: absolute;
        top: -1.5in;
        left: -1.5in;
      }
    }
  }

  .planOnAPage-Poster {
    @include clearfix;
    // box-shadow: inset rgba(green,0.25) 0px -2px 0px 0px; Temporarily highlight the bottom edge of the printable area
    display: flex;
    flex-wrap: wrap;
    width: 16.6in;
    height: 10.6in;
    margin: 0.2in;
    overflow: visible;
    position: relative;

    @include print {
      box-shadow: none;
    }

    .chartIcon {
      display: none;
    }
  }

  .richTextEditor {
    overflow-wrap: normal !important;
    word-wrap: normal !important;
    word-break: normal !important;
    hyphens: none !important;
  }

  table {
    tr {
      td {
        background: transparent;
        border-bottom: 0;
        padding: 0.6rem 0.5rem;

        &:first-child {
          .statusIndicatorDecorator img {
            float: left;
          }
        }

        &:last-child {
          .statusIndicatorDecorator img {
            float: right;
          }
        }

        &:only-child {
          .statusIndicatorDecorator img {
            float: none;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }

      &.highlighted td,
      td.highlighted {
        @include printHeaderStyle;
      }
    }
  }

  .planOnAPageSection {
    border: 2px solid $gold;
    font-size: 8px;
    flex: 1 1 100%;
    margin-bottom: 0.75rem;
    padding: 0 0.5rem 0.5rem;
    page-break-inside: avoid;

    &.empty {
      display: none !important;
    }

    @include print {
      border-color: darken($gold,20%);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .chartTitle {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0;
    font-size: 10px;
    font-weight: bold;
    line-height: 1;
    text-align: left;

    @include print {
      display: block;
    }

    img {
      max-height: 14px;
      vertical-align: middle;
      display: inline-block;
      position: relative;
      float: right;
    }
  }

  .planOnAPageTitle {
    width: calc(100% + 1rem);
    position: relative;
    left: -0.5rem;
    background: $gold;
    font-family: $font-primary;
    font-weight: 600;
    font-size: 12px;
    line-height: 1;
    padding: 0.35rem 0.35rem 0.4rem 0.35rem;
    margin-bottom: 0.5rem;
    color: white;
    letter-spacing: 0;

    @include print {
      background: darken($gold,20%);
    }
  }

  // Empty & Missing Styles
  .chartEmpty,
  .chartMissing {
    font-size: 12px;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
    max-width: 200px;
    font-style: italic;
    font-family: $font-secondary;
    font-weight: 700;
  }

  // Decorators
  .statusIndicator:not(.statusAndTrendIndicator) {
    max-width: 14px;
    max-height: 14px;

    &.status-null {
      max-width: 10px;
      max-height: 10px;
    }

    &:before {
      display: none !important; // Disable Image Replacement - Chrome can force background colors
    }
  }

  .statusIndicator.statusAndTrendIndicator {
    max-width: 20px;
    max-height: 20px;

    &.status-null {
      max-width: 16px;
      max-height: 16px;
    }

    &:before {
      display: none !important; // Disable Image Replacement - Chrome can force background colors
    }
  }

  .statusIndicatorDecorator img {
    max-width: 14px;
    max-height: 14px;
  }

  // LIFEPLAN: Structure
  .planOnAPage-Lifeplan {

    div.left {
      background: white;
      position: absolute;
      top: 2%;
      left: 0;
      width: 38.5%;

      div.split {
        width: 49.25%;
        float: left;

        + div.split {
          float: right;
        }
      }
    }

    div.right {
      background: white;
      position: absolute;
      top: 2%;
      right: 0;
      width: 61%;
    }
  }


  // STRATOP: Structure
  .planOnAPage-Stratop {
    div.left {
      background: white;
      position: absolute;
      top: 2%;
      left: 0;
      width: 19%;
    }

    div.center {
      background: white;
      position: absolute;
      top: 2%;
      left: 19.5%;
      width: 16%;
    }

    div.right {
      background: white;
      position: absolute;
      top: 2%;
      right: 0;
      width: 64%;
    }
  }

  // LIFEPLAN: Individual Chart Styles
  .turningPointProfile {
    .rankGraph {
      text {
        font-family: $font-primary;
        font-size: 13px;
      }
    }
  }

  .thinkingWavelengthLifeplan {
    .thinkingWavelengthWrap {
      clear: both;
      display: flex;
      padding: 0.5rem;

      svg {
        flex: 1 1 auto;
        margin-top: 0.5rem;
        width: auto;
      }

      span {
        flex: 0 1 auto;
        background-color: white;

        &:first-child {
          padding-right: 10px;
        }

        &:last-child {
          padding-left: 10px;
          text-align: right;
        }
      }
    }
  }

  .internalWiring {
    position: static;
    height: auto;
    max-width: 100%;
    width: 100%;

    .internalWiringSection {
      &:first-child {
        @include printHeaderStyle;
        @include clearfix;
        margin-bottom: 0.5rem;

        p {
          margin: 0;
        }
      }
    }

    .internalWiringDrivers,
    .internalWiringComfort {
      @include sectionTitle;
      float: left;
      width: auto;
    }

    .internalWiringComfort {
      float: right;
    }

    .internalWiringSection.internalWiringPrimary,
    .internalWiringSection.internalWiringSecondary {
      clear: both;
      display: flex;
      padding: 0 0.5rem;

      svg {
        flex: 1 1 auto;
        margin-top: 0.5rem;
        width: auto;
      }

      span {
        flex: 0 1 auto;
        background-color: white;

        &:first-child {
          padding-right: 10px;
        }

        &:last-child {
          padding-left: 10px;
          text-align: right;
        }
      }
    }

    .internalWiringPrimary {
      margin-bottom: 1rem;

      span {
        background-color: $white;
      }
    }
  }

  .talentHeartAssessment {
    .talentHeartAssessmentInner {
      padding: 2rem;
      display: flex;

      > div {
        flex: 1 1 auto;
        left: auto;
        right: auto;
        top: auto;
        bottom: auto;

        &.talent-heart-x {
          flex: 0 0 50px;
          position: relative;
        }

        &.talents {
          padding: 2rem;
        }

        &.heart {
          padding: 2rem;
        }

        &.talents,
        &.heart {
          border-width: 1px;
          text-align: center;

          h2 {
            text-transform: uppercase;
            font-style: italic;
            text-decoration: underline;
            margin-bottom: 0.5rem;
            font-size: 12px;
            letter-spacing: 1px;
          }

          ul {
            list-style: none;

            li {
              margin-bottom: 0.5rem;

              h3 {
                font-style: italic;
                font-size: 10px;
                margin-bottom: 0.25rem;
              }

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  .lifePlanAccountability {
    .lifePlanAccountabilityInner {
      height: auto;
      padding: 0;
    }

    ul {
      margin-bottom: 0.25rem;
      list-style: none;

      h2 {
        @include printHeaderStyle;
      }

      ul {
        padding: 0.5rem;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  // STRATOP: Individual Chart Styles

  .coreAssumptions {

    table {
      border-spacing: 0;
      display: block;

      tr {
        display: block;

        td {
          display: block;
          width: 100%;

          &:nth-child(2n-1) {
            padding-bottom: .25rem;
          }

          &:nth-child(2n) {
            padding-bottom: .5rem;
          }
        }
      }
    }
  }

  .ourMission {

    > span {
      display: block;
      font-size: 12px;
      text-align: center;
      font-style: italic;
    }
  }

  .coreValuesStratop,
  .coreValuesLifeplan {

    table {
      border-spacing: 0;
      table-layout: auto;

      tr {
        td {
          vertical-align: middle;
        }

        &.highlighted:first-child {
          display: none;
        }

        &:not(.highlighted) {
          td {
            padding: 0.3rem 0.5rem 0 0.5rem;
          }
        }

        td.numbers {
          display: none;
        }
      }
    }
  }

  .ourBigIdeaCoreStrategies {

    table {
      border-spacing: 0;
      table-layout: auto;

      tr {
        td {
          vertical-align: top;
        }

        &.highlighted:first-child {
          display: none;
        }

        &:not(.highlighted) {
          td {
            padding: 0.3rem 0.5rem;
          }
        }

      }
    }
  }

  .valueBuildingCycle,
  .replenishmentCycle {

    .stickyTitle,
    .editableTitleWrap {
      display: none;
    }

    .statusIndicator:not(.statusAndTrendIndicator) {
      max-width: 32px;
      max-height: 32px;
    }

    .cycleWrapper {
      position: relative;
      width: 280px;
      height: 290px;
      overflow: hidden;

      .cycle {
        width: 560px;
        height: 560px;
        transform: scale(0.5);
        position: absolute;
        left: -140px;
        top: -130px;

        .item > span {
          font-size: 13px;
          top: 47px;
        }
      }
    }
  }

  .ourVision {

    h2 {
      @include printHeaderStyle;
      margin-bottom: 0.5rem;
    }

    div.column:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  // LifePlan Dashboard

  .myLifeDashboard {
    .myLifeDashboardInner {
      div.desktop {
        > section {
          &.risks {
            width: 22%;
            > ul {
              li {
                padding: 10px 0 10px 10px;
                border-bottom: 2px solid $greylight;

                .statusIndicator {
                  margin: 0 4px 0 2px;
                }

                span {
                  font-weight: bold;

                  &:first-child {
                    font-size: 10px;
                  }
                }
              }
            }
          }

          &.direction {
            width: 42%;

            > ul {
              li:not(last-child) {
                padding-bottom: 0.5rem;
              }
            }
          }

        }
      }
    }
  }

  // SHARED: Individual Chart Styles

  .myLifeDashboard,
  .strategicDashboard {
    padding-bottom: 0.1in;
    min-height: 3.3in;

    .myLifeDashboardInner,
    .strategicDashboardInner {
      @include clearfix;
      display: block;

      div.desktop {
        display: block;

        > section {
          float: left;
          width: 32%;
          margin-left: 2%;

          &:first-of-type {
            margin-left: 0;
          }

          h2 {
            font-size: 10px;
            min-height: 0;
            font-family: $font-primary;
            text-align: center;
            line-height: 1.0rem;
            padding: 0.4rem 0.5rem 0.3rem;

            + span {
              font-size: 9px;
            }
          }

          &.direction {
            ul {
              margin: 0;

              li {
                padding: 0;
                border: 0;
                font-size: 9px;
                width: 100%;

                &:last-child {
                  padding-bottom: 0;
                }

                h3 {
                  @include printHeaderStyle;
                  margin-bottom: 0.5rem;
                }

                div.richTextEditor {
                  margin: 0 0.5rem;
                }
              }
            }
          }

          &.performanceModel {
            ul {
              column-count: 2;
              margin: 0;

              li {
                display: inline-block;
                padding: 0 0 0.5rem 0;
                border: 0;
                font-size: 9px;
                width: 100%;

                &:last-child {
                  padding-bottom: 0;
                }

                h3 {
                  @include printHeaderStyle;
                  margin-bottom: 0.5rem;
                }

                div.richTextEditor {
                  margin: 0 0.5rem;
                }
              }
            }
          }

          &.risks ul.horizontalDrivers ,
          &.performanceDrivers ul.horizontalDrivers ,
          &.vitalSigns ul {
            li {
              padding: 0;

              div.label {
                font-size: 9px;
                margin: 0.7rem 0 0 1rem;
              }

              div.lineWrap {
                flex: 0 0 45%;
                margin: 0 15px;

                div.line {
                  min-height: 40px;
                  transform: translate(-15px, 0);
                  width: calc(100% + 30px);
                }

                div.marker {
                  width: 27px;
                  transform: translate(-14px, 0);

                  .statusIndicator {
                    width: 20px;
                    height: 20px;
                    margin: 5px auto 3px auto;

                    + span {
                      margin-top: -4px;
                      font-size: 10px;
                    }
                  }
                }
              }
            }

            &.reverse {
              li {
                div.label {
                  margin: 0.7rem 1rem 0 0;
                }
              }
            }
          }

          &.risks {
            // Stratop
            .risksInner {
              padding-left: 25px;

              .axisGroup {
                left: 5px;

                div.axisLine {
                  left: 0;
                }

                div.axisLabel {
                  font-size: 9px;
                  color: $gold;

                  @include print {
                    color: darken($gold,20%);
                  }

                  &.low {
                    bottom: -10px;
                  }
                }
              }
            }
          }
        }
      }

      div.mobile {
        display: none;
      }
    }
  }

  .lifeInitiativesProfile,
  .actionInitiativesProfile {
    padding-bottom: 0.25rem;

    // Hide the full chart elements not needed here
    .stickyTitle,
    .editableTitleWrap,
    .chartCaption,
    .winWheel {
      display: none;
    }

    table {
      border-spacing: 0;

      td, th {
        width: 1%;
      }

      th {
        font-size: 10px;
        min-height: 0;
        font-family: $font-primary;
        text-align: center;
        line-height: 1.0rem;
        padding: 0.4rem 0.5rem 0.3rem;
        background: $goldlight;
      }

      td {
        padding: 0.4rem 0.5rem;

        span.leader {
          display: inline;
        }

        &:first-of-type {
          border-bottom: 1px solid white;
        }
      }

      tr:nth-child(2n) td {
        background: rgba(black,0.03);
      }
    }
  }

  .lifeInitiativesProfile {

    /* Fix Header Alignment */
    table {
      th {
        span.startDate {
          display: inline;
        }

        &:nth-child(4) {
          text-align: left;
        }

        &:nth-child(5) {
          text-align: right;
          text-indent: 9999px;
          max-width: 50px;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }

    /* Styles for Steps + Dates */
    table td.steps div {
      display: inline;
      float: left;
      padding: 1px 3px 0px 3px;
      border-radius: 3px;
      margin: 0 2px 2px 0;
      border: 1px solid rgba(black,0.1);

      span.step {
        line-height: 1.0;
        padding: 0;
        margin: 0;
        display: inline;
      }

      span.date {
        line-height: 1.0;
        display: inline;

        margin-left: 0.3rem;

        &:before {
          content: "(";
        }

        &:after {
          content: ")";
        }

        &:empty {
          margin-left: 0;

          &:before {
            content: "";
          }

          &:after {
            content: "";
          }
        }
      }
    }
  }

  .actionInitiativesProfile {
    table {

      /* Fix Header Alignment */
      th {
        span.by, span.leader {
          display: inline;
        }

        &:nth-child(4),
        &:nth-child(7) {
          text-align: left;
        }

        &:nth-child(5) {
          text-align: right;
          text-indent: 9999px;
          max-width: 50px;
          overflow: hidden;
        }
      }

      /* Lower Padding, Set Widths on 'Deliverable' */
      th:nth-child(4),
      td:nth-child(3),
      td.coreIssue ~ td:nth-child(4)  {
        width: 3%;
      }

      td.coreIssue ~ td:nth-child(3) {
        padding-bottom: 0.4rem;
        width: 1%;
      }

      /* Remove Column for Leader (Now it's grouped with Team) */
      th:nth-child(6),
      td:nth-child(4),
      td.coreIssue ~ td:nth-child(5)  {
        display: none;
      }

      td:nth-child(4),
      td.coreIssue ~ td:nth-child(5) {
        padding-bottom: 0.1rem;
      }

      td.coreIssue ~ td:nth-child(4) {
        display: table-cell;
      }
    }

    /* Styles for Deliverables + Dates */
    div.deliverableWrap {
      display: inline;
      float: left;
      padding: 1px 3px 0px 3px;
      border-radius: 3px;
      margin: 0 2px 2px 0;
      border: 1px solid rgba(black,0.1);

      span.deliverable {
        line-height: 1.0;
        padding: 0;
        margin: 0;
        display: inline;
      }

      span.date {
        line-height: 1.0;
        display: inline;

        margin-left: 0.3rem;

        &:before {
          content: "(";
        }

        &:after {
          content: ")";
        }

        &:empty {
          margin-left: 0;

          &:before {
            content: "";
          }

          &:after {
            content: "";
          }
        }
      }
    }
  }

  /*
  .lifeInitiativesProfile .lifeInitiativesProfileInner,
  .actionInitiativesProfile .actionInitiativesProfileInner {
    display: flex;

    > section {
      flex: 1 1 auto;
      margin: 0 0.5rem;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      h1 {
        margin: 0;
        color: white !important;
        border: 0px solid;
        padding: 0.4rem;
        font-family: $font-primary;
        font-weight: bold;
      }

      ul {
        list-style: none;
        margin: 0;

        &.empty {
          padding: 0;
          opacity: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        > li {
          h2 {
            @include printHeaderStyle;
            background: $greylight;
            margin: .5rem 0;
          }

          div {
            display: flex;
            margin-bottom: 0.4rem;
            padding-left: 0.4rem;

            span {
              flex: 1 1 auto;
            }

            strong {
              flex: 0 1 2.5rem;
              padding-left: 1rem;
              margin-left: 0.5rem;
              text-align: right;
              position: relative;

              &:empty {
                display: none;
              }

              &:not(:empty):before {
                content: "";
                position: absolute;
                border-top: 2px solid #aaa;
                width: 0.5rem;
                left: 0rem;
                top: 0.6rem;
                height: 0;
              }
            }
          }
        }
      }
    }
  }

  .lifeInitiativesProfile .lifeInitiativesProfileInner {
    > section {
      > ul {
        > li {
          div {
            strong {
              flex: 0 1 5.5rem;
            }
          }
        }
      }
    }
  }
  */
}
