
@mixin button($type: "outline", $color: "blue") {
  @include transition;
  background: transparent;
  cursor: pointer;
  display: inline-block;
  padding: 8px 15px;
  font-family: $font-primary;
  font-weight: 600;
  font-size: 15px;
  line-height: 1.5;
  text-decoration: none;

  &:hover {
    box-shadow:  0, 1px, 4px $black20;
  }

  &:focus {
    outline: 0;
  }

  @if ($type == "outline") {
    border-radius: 2px;
    border: 2px solid $blue;
    color: $blue;

    &:hover,
    &:focus {
      background: $blue25;
    }

    &[disabled] {
      border-color: $grey;
      color: $grey;
      opacity: 0.4;

      &:hover,
      &:focus {
        cursor: default;
        box-shadow: 0 0 0 $black;
        background-color: white;
        border-color: $grey;
        color: $grey;
      }
    }

    @if ($color == "teal") {
      border: 2px solid $teal;
      color: $teal;

      &:hover,
      &:focus {
        background: rgba($teal, .25);
      }
    }

    @if ($color == "green") {
      border: 2px solid $green;
      color: $green;

      &:hover,
      &:focus {
        background: rgba($green, .25);
      }
    }

    @if ($color == "red") {
      border: 2px solid $red;
      color: $red;

      &:hover,
      &:focus {
        background: rgba($red, .25);
      }
    }
  }

  @if ($type == "solid") {

  }
}

a.button,
input[type='submit'],
button {
  @include button("outline", "blue");

  &.green {
    @include button("outline", "green");
  }

  &.red {
    @include button("outline", "red");
  }
}


@mixin moveButton {
  border-radius: 3px;
  background: white url('../image/icon-move.svg') no-repeat center;
  background-size: contain;
  border: 2px solid transparent;
  color: black;
  cursor: move;
  width: 40px;
  height: 40px;
  padding: 0;

  &:hover,
  &:focus {
    border: 2px solid $gold;
    background-color: rgba($gold, .25);
  }
}

button.close {
  border-radius: 3px;
  background: white url('../image/x-icon.svg') no-repeat center;
  background-size: contain;
  border: 5px solid transparent;
  color: $red;
  width: 24px;
  height: 24px;
  padding: 0;

  &:hover,
  &:focus {
    background-color: #ffdacc;
    box-shadow: none;
  }
}

button.delete {
  border-radius: 3px;
  background: white url('../image/trash-icon.svg') no-repeat center;
  background-size: contain;
  cursor: pointer;
  border: 2px solid transparent;
  color: $red;
  width: 40px;
  height: 40px;
  padding: 0;

  &:hover,
  &:focus {
    border: 2px solid $red;
    background-color: #ffdacc;
  }

  &[disabled] {
    background-color: $greylight;
    border: 2px solid $greymedium;
  }

  &:active {
    background-color: transparentize($red, .7) !important;
    border-color: darken($red, 3%);
  }
}

button.add {
  border-radius: 3px;
  background: transparent;
  border: 2px solid currentColor;
  color: $blue;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  padding: 2px 9px;
  font-size: 14px;
  height: 32px;
  line-height: 14px;
  white-space: nowrap;
  margin-bottom: 8px;

  &:hover,
  &:focus {
    border: 2px solid $blue;
    background-color: $blue25;
    color: $blue;
  }

  span.plus {
    font-size: 26px;
    width: 14px;
    height: 24px;
    line-height: inherit;
    font-weight: normal;
    vertical-align: -3px;
    text-align: center;
    color: inherit;
  }
}

button.cancel {
  border-color: $red;
  color: $red;
  float: left;

  &:hover,
  &:focus {
    border: 2px solid $red;
    background-color: #ffdacc;
  }
}

.richTextEditor button {
  padding: 0;
}
