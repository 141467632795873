@import "../../css/_variables";
@import "../../css/_mixins";
@import "TableOverflowWrapper";

table.turningPointProfile {
  margin-top: 5rem;
}

.turningPointProfile {
  page-break-inside: auto;
  border-spacing: 0;
  width: 100%;

  .editor & {
    padding-bottom: 4rem;

    .chartTitleWrap {
      margin-bottom: 8rem;
    }
  }

  thead {
    background-color: $white;
    z-index: 5;

    .inlineInput {
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }

  tr {
    page-break-inside: avoid;
  }

  th {
    background: $white;
    text-align: left;

    .viewer & {
      padding: 1rem;
    }

    @include print {
      font-size: $print-font-size;
    }
  }


  // Cell Styles
  td {
    vertical-align: top;
    background: transparent;
    text-align: left;
    border-bottom: none;

    .viewer & {
      vertical-align: middle;
    }

    .inlineInput {
      min-height: 46px;
      font-size: 12px;
      line-height: 1.2;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    &.cellHighlight {
      background: $greylight;
      font-weight: 700;
    }

    &.cellHighlightBright {
      font-weight: 700;
      background: rgba($gold,0.5);
      border-bottom: 2px solid $gold;
    }

    @include print {
      font-size: $print-font-size;
    }
  }

  tr.gate.active {
    border-bottom: 2px solid $red;
    font-family: $font-primary;
    font-weight: 600;
  }

  tr:not(.gate) td {
    overflow-x: hidden;
    text-overflow: ellipsis;
  }


  tbody {

    td {
      page-break-inside: avoid; // avoid breaking themes across pages

      .editor & {
        border-color: transparent;

        &:last-of-type {
          padding-right: 0;
        }
      }

      div button.delete {
        position: absolute;
        left: 2rem;
      }
    }

    .delete {
      max-width: 100px;
    }

    tr {

      &:first-of-type {
        td {
          border-top: none;
        }
      }

      &.lastInTheme {
        td {
          border-bottom: 2px solid $gold;
        }
      }

      &.add {
        td {
          text-align: center;
          padding-top: 8px;
          padding-bottom: 8px;
          border-top: 2px solid $gold;

          &:first-of-type {
            background: none;
            border: none;
          }

          button {
            text-transform: uppercase;
            color: $blue;
            border: 2px solid transparent;
            padding: .5rem 2rem;
            font-size: 14px;

            &:hover,
            &:focus {
              color: $bluebright;
              border: 2px solid currentColor;
            }
          }
        }
      }
    }
  }

  // Header Cells
  thead {
    @include print {
      display: table-header-group;
      border-top: 2px solid red;
      border-bottom: 2px solid red;
    }

    tr:first-of-type th {
      // Hide thead in view mode
      .viewer & {
        padding: 0;
        border: 0;
        font-size: 0;

        @include print {
          font-size: $print-font-size;
          padding: 5px 8px;
          border-top: 2px solid $gold;
          border-bottom: 2px solid $gold;
          position: static;
          background: white;
        }
      }
    }

    th {
      vertical-align: top;
      min-height: 40px;
      font-family: $font-primary;
      font-weight: 600;
      font-size: 13px;
      text-transform: uppercase;
      border-top: 2px solid $gold;
      border-bottom: 2px solid $gold;
      position: relative;
      text-align: left;

      .editor & {
        padding: 12px 8px;
        min-height: 0;
        vertical-align: middle;
        position: sticky;
        top: -2px;
        z-index: 100;

        &:nth-last-child(2) {
          padding-right: 0;
        }
      }

      input,
      span {
        font-weight: 700;
        text-transform: uppercase;
        vertical-align: top;
      }

      button {
        position: absolute;
        top: -50px;
        left: calc(50% - 25px);
      }

      &.add {
        width: 55px;
      }

      &:first-of-type {
        // delete row
        .editor & {
          width: 55px;
        }
      }

      &.themeCol {
        width: 155px;

        @include print {
          width: auto;
        }
      }

      &.turningPointCol {
        .editor & {
          width: 150px;
        }
      }

      &.ageCol {
        width: 50px;
      }

      &.rankCol {
        width: 70px;
      }

      &:last-of-type {
        .editor & {
          border-color: transparent;
        }
      }
    }
  }

  // Gates
  .gate {
    border: 0;
    padding: 0;
    position: relative;

    td {
      padding: 0;
    }

    td,
    tr {
      text-align: center;
      border: 0;
    }

    .viewer & {
      td.gateActiveSpan {
        background: transparent;

        span {
          background-color: $red;
          margin-left: -40%;
          color: $white;
          border-radius: 2px;
          display: inline-block;
          position: relative;
          top: 11px;
          font-weight: 400;
          font-size: 12px;
          min-height: 0;
          line-height: 1;
          padding: 4px 10px;
          z-index: 100;

          @include print {
            color: $red;
            background: white;
            border-left: 2px solid $red;
            border-right: 2px solid $red;
            border-radius: 0;
            font-size: 11px;
            font-weight: bold;
            padding: 0px 10px;
            top: 9px;
          }
        }
      }
    }

    span,
    .blank,
    input {
      font-style: normal;
      font-weight: 700;
      border-radius: 0;
      text-align: center;
      text-transform: uppercase;
      border-top: 2px solid transparentize($grey, .9);
      border-bottom: 2px solid transparentize($grey, .9);
      border-left-color: transparent;
      border-right-color: transparent;
      background-color: transparentize($grey, .9);
      line-height: $input-line-height-default;
      transition: all .2s ease;
      display: block;
      width: 100%;

      &:hover,
      &:focus {
        cursor: text;
      }

      &:focus {
        background-color: transparentize($redLight, .5);
        border-color: $red;
        cursor: text;
      }
    }

    &.active {
      + tr td {
        padding-top: 20px;
      }

      td {
        border-bottom: 2px solid $red;
      }

      &.lastInTheme {
        td {
          padding-bottom: 0;
          border-color: $red;
        }
      }

      span,
      input {
        left: 0;
        top: 0;
        border: 0;
        font-weight: 700;
        letter-spacing: .05rem;
        line-height: 36px;
        min-height: 40px;
        color: $red;
        background-color: transparentize($red, .8);
        padding: .2rem 1rem;
        border-radius: 0;
      }

      .delete {
        visibility: visible;
      }

      .editor & {
        background-color: transparent;
        color: $red;
        text-align: center;

        span {
          width: 100%;
          display: block;
        }
      }
    }

    span,
    .delete {
      width: auto;
      display: inline;
    }

    .delete {
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 1rem;
      width: 30px;
      display: block;
      background-color: transparent;
    }
  }

  &.stickyHeaderTable {

    thead {
      padding: 1rem;
      visibility: visible;
      height: 1rem;
      width: 100%;

      tr:first-of-type th {
        padding: 8px;
        font-size: 13px;
        border-top: 2px solid $gold;
        border-bottom: 2px solid $gold;
      }
    }

    tbody {
      display: none;
    }

    @include print {
      display: none;
    }
  }
}


// View Mode
.rankGraphWrap {
  width: 100%;
  z-index: 5;
  position: sticky;
  top: calc(48px + 30px); /* 30px for stickyTitle */
  background-color: $white;
  padding-top: 8px;

  @include print {
    position: static;
  }

  .rankGraph {
    position: relative;
    line-height: 1;
    padding-bottom: 1rem;

    text {
      font-family: $font-primary;
      font-weight: 700;
      stroke-width: 0;
      fill: $grey;
    }

    .rankLabel {
      fill: $grey;
      font-weight: 400;
    }

    .viewer & {
      padding-top: 0;
      background: $white;
      position: relative;
    }
  }
}

@include medium-down {
  @media screen {
    .viewer .turningPointProfile {
      &.stickyHeaderTable {
        display: none;
      }

      thead {
        display: none;
      }

      tbody {
        tr {
          display: block;

          td {
            display: block;

            &:empty {
              display: none;
            }

            &:before {
              content: attr(data-label);
              color: $black30;
              display: block;
              font-weight: bold;
              text-transform: uppercase;
              text-align: left;
            }

            &.cellHighlightBright {
              padding-top: 8px;
              border-top: 2px solid $gold;
            }

            &.cellHighlight {
              padding-top: 8px;

              &:first-child {
                border-top: 2px solid $greymedium;
              }
            }
          }

          &.gate {

            &:not(.active) {
              display: none;
            }

            td:not(.gateActiveSpan) {
              display: none;
            }

            td.gateActiveSpan {
              span {
                margin-left: 0;
              }
            }

            &.active + tr td {
              padding-top: 8px;
            }
          }
        }
      }
    }
  }
}

@include print {

  .rank-graph-wrap {
    background: transparent;
    transform: scale(1.4);
  }

}
