/*
@page {
  size: 8.5in 10.8in;
  margin: 0.2in 0 0 0;
}

@-moz-document url-prefix() {
  @page {
    margin: 1in 0 0 0;
    size: 8.5in 10in;
  }
}

@include print {

  body {
    overflow: visible;

    ul.charts {
      height: auto;
      overflow: visible;
    }

    // Page/Content
    .content {
      padding: 0;
      margin: 0;
      min-height: 0;
    }
  }

  nav,
  div.playbookMeta {
    display: none;
  }

  .chartList.viewer {
    margin-top: 0;
    height: auto !important;
  }

  table,
  figure {
    page-break-inside: avoid;
  }

  // Charts
  ul.viewer {
    > li.playbookChart {
      @include box-sizing;
      display: block;
      padding: .5in 0 0 0;
      border-bottom: 0;
      max-width: 7.5in;
      min-height: 10.5in;
      margin: 0 auto;
      page-break-inside: avoid;  // removed in order to allow page break on long tables
      page-break-after: auto;

      &:last-child {
        page-break-after: avoid;
        height: auto;
        min-height: 0;
      }

      .chart {
        padding: 0;
      }

      //&.locked:nth-child(3) {
        //+ li:not(.locked) {
          //// TODO How do we remove the blank page before this chart?
        //}
      //}
    }

    div.chart h2.chartCaption {
      margin-bottom: 2rem;
    }

    > li.playbookChart > div.chart {
      max-width: 7.5in;
      margin: 0 auto;
    }
  }

  // Re-size
  .lifeCycleAnalysis,
  .replenishmentCycleWrapper {
    display: block;
    max-width: 6in;
    margin: 0 auto;
  }

  // Life Cycle Analysis
  .lifeCycleAnalysis {
    max-width: 5.5in;
  }

  // Replenishment Cycle
  .replenishmentCycleWrapper {
    max-width: 5.5in;
  }

  .replenishmentCycle .item > span {
    padding: 5px;
  }

  // Tables
  .filterTable td,
  .filterTable th {
    font-size: 12px;
    line-height: 14px;
    padding: 4px 10px;
  }

  // Rich Text
  .richTextEditor {
    ul, ol {
      li {
        margin: 0;
        padding-top: 5px;
      }
    }
  }


}
*/

@include print {

  // Status Indicator
  .statusIndicator {
    transform: scale(0.75);

    &.status-null {
      content: url('../image/status-null.svg');
    }

    // Green
    &.status-1:before {
      content: url('../image/status-green.svg');
    }

    // Green-Yellow
    &.status-2:before {
      content: url('../image/status-green-yellow.svg');
    }

    // Yellow
    &.status-3:before {
      content: url('../image/status-yellow.svg');
    }

    // Yellow-Red
    &.status-4:before {
      content: url('../image/status-yellow-red.svg');
    }

    // Red
    &.status-5:before {
      content: url('../image/status-red.svg');
    }

    &.statusAndTrendIndicator {

      &.status-null  {
        width: 20px;
      }

      &.status-1-1  {
        content: url('../image/status-arrows/green-up.svg');
      }

      // Green Flat
      &.status-1-2 {
        content: url('../image/status-arrows/green-flat.svg');
      }

      // Green Down
      &.status-1-3 {
        content: url('../image/status-arrows/green-down.svg');
      }

      // Yellow Up
      &.status-2-1 {
        content: url('../image/status-arrows/yellow-up.svg');
      }

      // Yellow Flat
      &.status-2-2  {
        content: url('../image/status-arrows/yellow-flat.svg');
      }

      // Yellow Down
      &.status-2-3 {
        content: url('../image/status-arrows/yellow-down.svg');
      }

      // Red Up
      &.status-3-1 {
        content: url('../image/status-arrows/red-up.svg');
      }

      // Red Flat
      &.status-3-2 {
        content: url('../image/status-arrows/red-flat.svg');
      }

      // Red Down
      &.status-3-3 {
        content: url('../image/status-arrows/red-down.svg');
      }
    }

    .performanceDrivers &,
    .vitalSigns & {

      &.status-1-1  {
        content: url('../image/status-arrows-horizontal/green-horizontal-left.svg');
      }

      // Green Flat
      &.status-1-2 {
        content: url('../image/status-arrows-horizontal/green-horizontal-flat.svg');
      }

      // Green Down
      &.status-1-3 {
        content: url('../image/status-arrows-horizontal/green-horizontal-right.svg');
      }

      // Yellow Up
      &.status-2-1 {
        content: url('../image/status-arrows-horizontal/yellow-horizontal-left.svg');
      }

      // Yellow Flat
      &.status-2-2  {
        content: url('../image/status-arrows-horizontal/yellow-horizontal-flat.svg');
      }

      // Yellow Down
      &.status-2-3 {
        content: url('../image/status-arrows-horizontal/yellow-horizontal-right.svg');
      }

      // Red Up
      &.status-3-1 {
        content: url('../image/status-arrows-horizontal/red-horizontal-left.svg');
      }

      // Red Flat
      &.status-3-2 {
        content: url('../image/status-arrows-horizontal/red-horizontal-flat.svg');
      }

      // Red Down
      &.status-3-3 {
        content: url('../image/status-arrows-horizontal/red-horizontal-right.svg');
      }
    }

    .risks & {

      &.status-1-1  {
        content: url('../image/status-arrows-horizontal/green-horizontal-right.svg');
      }

      // Green Flat
      &.status-1-2 {
        content: url('../image/status-arrows-horizontal/green-horizontal-flat.svg');
      }

      // Green Down
      &.status-1-3 {
        content: url('../image/status-arrows-horizontal/green-horizontal-left.svg');
      }

      // Yellow Up
      &.status-2-1 {
        content: url('../image/status-arrows-horizontal/yellow-horizontal-right.svg');
      }

      // Yellow Flat
      &.status-2-2  {
        content: url('../image/status-arrows-horizontal/yellow-horizontal-flat.svg');
      }

      // Yellow Down
      &.status-2-3 {
        content: url('../image/status-arrows-horizontal/yellow-horizontal-left.svg');
      }

      // Red Up
      &.status-3-1 {
        content: url('../image/status-arrows-horizontal/red-horizontal-right.svg');
      }

      // Red Flat
      &.status-3-2 {
        content: url('../image/status-arrows-horizontal/red-horizontal-flat.svg');
      }

      // Red Down
      &.status-3-3 {
        content: url('../image/status-arrows-horizontal/red-horizontal-left.svg');
      }
    }
  }
}
