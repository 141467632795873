/* Rich Text Editor
---------------------------------------------*/
.richTextEditor {
  transition: border .25s ease;
  display: inline-block;
  width: 100%;

  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  // word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  hyphens: auto;

  ul,
  ol {
    padding-left: 15px;
    @include print {
      li {
        padding-top: 2px;
      }
    }
  }

  ul {
    list-style-type: disc;
  }

  .viewer & {
    /* These are technically the same, but use both */
    overflow-wrap: normal;
    word-wrap: normal !important;
    word-break: normal !important;
    hyphens: none;
    height: auto;
  }

  .editor & {
    position: relative;
    border: 1px solid $input-border-default;
    cursor: text;
    padding: 5px 8px;
    border-radius: 2px;

    &:hover,
    &:focus {
      border-color: $input-border-default;
    }
  }
}

/* TEMPORARY RICH TEXT EDITOR TOOLBAR FIX
   TODO CHECK FOR DRAFT-JS-PLUGINS MERGE/PULL REQEUST
------------------------------------------------------*/
.draftJsToolbar__toolbar__dNtBH {
  white-space: nowrap;
  top: -40px !important;
  left: 50% !important;
}
