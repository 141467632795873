@import "../../css/_variables";
@import "../../css/_mixins";

.lifeCycleAnalysis {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.lifeCycleAnalysisInner {
  box-sizing: border-box;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 100%;
  user-select: none;
}

.lifeCycleAnalysisInner > svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.lifeCycleAnalysis svg text {
  font-family: $font-primary;
  font-weight: 600;
}

.lifeCycleAnalysis .item {
  padding-left: 15px;
  position: absolute;
  /* this should be translated such that the marker is at the origin */
}

.lifeCycleAnalysis .item:hover,
.lifeCycleAnalysis .item:focus {
  z-index: 100; /* bring to forefront if there are overlapping .items */
}

.lifeCycleAnalysis button.delete {
  border-color: $gold;
  position: absolute;
  top: -2px; /* container's border width */
  left: -2px; /* container's border width */
  width: 32px;
  height: 44px;
  z-index: 10;
}

.lifeCycleAnalysis .item .shift {
  box-sizing: border-box;
  border-radius: 3px;
  background: white;
  border: 2px solid $gold;
  display: block;
  padding-left: 0px;
  min-height: 32px;
  transform: translate(0px, -50%);
}

.editor .lifeCycleAnalysis .item .shift {
  padding-left: 30px;
}

/* Dot Handle */
.lifeCycleAnalysis .item .shift .inputWrapper:before {
  content: "";
  position: absolute;
  border-radius: 50%;
  background: $gold;
  font-size: 20px;
  border: 5px solid $gold;
  width: 0px;
  height: 0px;
  top: calc(50% - 5px);
  left: -21px;
}

.editor .lifeCycleAnalysis .item .shift .inputWrapper:before {
  cursor: move;
  cursor: grab;
}

/* Dot Handle - Line */
.lifeCycleAnalysis .item .shift .inputWrapper:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 15px;
  top: calc(50% - 1px);
  left: -15px;
  border-top: 2px solid $gold;
}

.editor .lifeCycleAnalysis .item .shift .inputWrapper:after {
  cursor: move;
  cursor: grab;
}

.lifeCycleAnalysis .item .shift span {
  border: 0;
  display: block;
  padding: 4px 8px;
  outline: 0;
  outline-offset: 0;
  line-height: 28px;
}

.viewer .lifeCycleAnalysis .item .shift span {
  line-height: 20px;
}

.editor .lifeCycleAnalysis .item .shift span {
  cursor: move;
  cursor: grab;
}

.lifeCycleAnalysis .item .shift input {
  border: 0;
  padding: 0;
  margin: 0;
  padding: 4px 8px;
  height: 28px;
  line-height: 28px;
}

.editor .lifeCycleAnalysis svg {
  cursor: copy;
}

.editor .lifeCycleAnalysis svg.dragging {
  cursor: move;
  cursor: grabbing;
}


/* Responsive
----------------------------------------------------*/

@include small-down {

  .lifeCycleAnalysis {
    width: 80%;
  }

  .lifeCycleAnalysis svg path {
    stroke-width: 8;
  }

  .lifeCycleAnalysis svg text {
    font-size: 44px;
  }

  .lifeCycleAnalysis .item .shift {
    min-height: 22px;
  }

  .lifeCycleAnalysis .item .shift span {
    font-size: 11px;
    line-height: 1.2;
    padding: 2px 8px;

  }
}