@import "../../css/_variables";
@import "../../css/_mixins";

.cycleWrapper {
  margin: 0 auto;
  max-width: 600px; /* Full-Width is huge on small viewports */
}

.cycle {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}

.cycle > button {
  position: absolute;
  top: calc(60% + 25px);
  left: calc(50% - 27px);
  font-size: 13px;
  padding: 2px 12px;
}

.cycle .item,
.cycle .center {
  box-sizing: border-box;
  border: 2px solid $gold;
  position: absolute;
  border-radius: 50%;
}

.cycle .item {
  background-color: white;
  box-shadow: 0 0 0 3px white;
}

.cycle .center {
  background-color: $gold;
  font-family: $font-primary;
  font-weight: bold;
  letter-spacing: .05rem;
}

.cycle .item > span,
.cycle .item > input,
.cycle .item > .richTextEditor,
.cycle .center > span,
.cycle .center > input {
  display: block;
  position: relative;
  top: 58px;
  text-align: center;
  padding: 5px 10px;
  font-family: $font-primary;
}

.cycleWrapper .cycle .item > span {
  font-weight: normal;
  border: 1px solid transparent;
  font-size: 12px;
  margin-top: 5px;
  font-weight: normal;
}

.cycleWrapper .cycle .item > input {
  font-family: $font-primary;
  background: none;
  font-size: 12px;
  border: 1px solid $black05;
  margin-top: 0;
  font-weight: normal;
}

.cycle .item > span,
.cycle .center > span  {
  display: block;
  font-size: 13px;
  line-height: 17px;
}

.cycleWrapper .cycle .center > span {
  border: 1px solid transparent;
}

.cycleWrapper .cycle .center > input {
  font-family: $font-primary;
  background: none;
  letter-spacing: .05rem;
  font-size: 13px;
  line-height: 26px;
  border: 1px solid black;
}

.editor .item > span,
.editor .center > span {
  cursor: text;
}

.cycle .item > input,
.cycle .center > input {
  box-sizing: border-box;
  width: 100%;
  background: rgba(0,0,0,0.1);
  border: 0px;
  color: $grey;
  display: inline-block;
  font-family: $font-primary;
  font-weight: normal;
  font-size: 13px;
  height: auto;
  line-height: 17px;
  margin: 0px;
  width: 100%;
  outline: 0;
  outline-offset: 0;
}

.cycle .statusIndicator {
  top: 12px;
  left: calc(50% - 17px);
}

.cycle ul {
  list-style-type: none;
}

.cycle li .delete {
  border-color: $gold;
  position: absolute;
  bottom: -15px;
  left: calc(50% - 15px);
  width: 30px;
  height: 30px;
}
.cycle .circle {
  border-radius: 50%;
  border: 2px solid #999;
  position: absolute;
}

/* Responsive
----------------------------------------------------*/

@media screen and (max-width: 480px) {

  .cycle {
    height: auto;
    padding-bottom: 0;

    .circle {
      display: none;
    }

    .center {
      position: relative;
      top: 0;
    }

    .item {
      border-radius: 10px;
      position: relative;
      top: 0 !important;
      left: 0 !important;
      margin: 20px auto 0 auto;
      padding: 0 45px 0 0;
      height: auto !important;
      min-height: 45px;
      width: 100% !important;

      .statusIndicator {
        top: calc(50% - 16px);
        right: 10px;
        left: auto;
      }

      > span {
        top: 0;
        left: 0;
        text-align: left;
        max-width: calc(100% - 40px);
      }

      &:before {
        content: "";
        background: $gold;
        width: 2px;
        height: 20px;
        position: absolute;
        top: -20px;
        left: calc(50% - 1px);
      }
    }
  }
}

/* Vertical Alignment
----------------------------------------------------*/

.viewer .cycle .item,
.viewer .cycle .center {
  display: table;

  > span {
    display: table-cell;
    position: static;
    vertical-align: middle;
    padding: 10px;
  }

  > .statusIndicator + span {
    padding-top: 45px;
  }

  > .statusIndicator.status-null + span {
    top: 0;
    padding-top: 10px;
  }
}
