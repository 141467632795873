@import "../../css/_variables";
@import "../../css/_mixins";

.winWheel {
  display: inline-block;
  width: 100%;
  position: relative;
  margin-top: 70px;
  page-break-before: always;
}

.winWheelInner {
  position: relative;
  height: 0;
  padding-bottom: 100%;

  @include medium-down {
    @media screen {
      height: auto;
      padding: 0;
    }
  }
}

.winWheelMobile {
  display: block;

  @include print {
    display: none;
  }

  @include medium {
    @media screen {
      display: none;
    }
  }

  div {
    margin: 0 0 15px 0;
  }

  h2 {
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    padding: 0.5rem;
    font-size: 1.6rem;
  }

  div:nth-child(1) h2 { color: #00a275; border-color: #00a275; }
  div:nth-child(2) h2 { color: #008fa7; border-color: #008fa7; }
  div:nth-child(3) h2 { color: #8e0f58; border-color: #8e0f58; }
  div:nth-child(4) h2 { color: #102777; border-color: #102777; }
  div:nth-child(5) h2 { color: #f1a600; border-color: #f1a600; }
  div:nth-child(6) h2 { color: #ff4500; border-color: #ff4500; }
  div:nth-child(7) h2 { color: #83888f; border-color: #83888f; }
  div:nth-child(8) h2 { color: #91d2c0; border-color: #91d2c0; }
  div:nth-child(9) h2 { color: #91cad4; border-color: #91cad4; }
  div:nth-child(10) h2 { color: #ca97b4; border-color: #ca97b4; }
  div:nth-child(11) h2 { color: #97a1c1; border-color: #97a1c1; }
  div:nth-child(12) h2 { color: #f1d391; border-color: #f1d391; }
  div:nth-child(13) h2 { color: #f7ad91; border-color: #f7ad91; }
  div:nth-child(14) h2 { color: #c5c7ca; border-color: #c5c7ca; }

  ul {
    padding-left: 2rem;
    margin: 1rem 0;
  }
}

.winWheel .winWheelInner svg {
  position: absolute;
  width: 100%;
  height: 100%;

  @include medium-down {
    @media screen {
      display: block;
      position: relative;
      width: 150px;
      height: 150px;
      margin: 0 auto;
    }
  }
}

.winWheel .chartTitle {
  margin-bottom: 0;

}

.winWheel button {
  display: block;
  margin: 1rem auto 0 auto;
}

.winWheel .item {
  position: absolute;
  border-radius: 50%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include medium-down {
    @media screen {
      display: none;
    }
  }
}

.winWheel .item > span {
  display: block;
  position: relative;
  text-align: center;
  font-size: 2.5rem;
  padding: 1rem;
  line-height: 1.1;

  @include large-down {
    font-size: 1.6rem;
  }

  @include print {
    font-size: 1.6rem;
  }
}

.winWheelInner ul {
  list-style-type: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.winWheel svg text {
  font-size: 1.25rem;
  font-family: $font-primary;
  font-weight: 600;

  @include medium-down {
    @media screen {
      display: none;
    }
  }
}

.winWheel svg line {
  @include medium-down {
    @media screen {
      display: none;
    }
  }
}


@include small-down {
  @media screen {
    .viewer {
      .winWheel {
        .item {
          transform: scale(1.1);

          span {
            font-size: 1.4rem;
          }
        }

        svg text {
          font-size: 3rem;
        }
      }
    }
  }
}
