@import "../../css/_variables";
@import "../../css/_mixins";
.thinkingWavelength {
  .editor & {
    max-width: $global-content-width-constrain;
    margin-left: auto;
    margin-right: auto;
  }

  @include print {
    display: block;
    max-width: 6in;
    margin: 0 auto;
    overflow: visible;

    svg {
      display: block;
      margin: 0 auto;
    }

    g.waves path {
      stroke: #A04783;
    }

    rect.scoreBackground {
      fill: #A04783;
    }
  }

  svg.defsOnly {
    position: absolute;
    visibility: hidden;
  }
}
/* Legend */
.thinkingWavelength .legend {
  flex-direction: row;
  display: flex;
}

.thinkingWavelength .legend > div {
  flex: 0 1 auto;
  min-width: 33.33%;
  max-width: 33.33%;
}

.thinkingWavelength .legend h2 {
  font-family: $font-primary;
  font-weight: 600;
  color: #302d5b;
  text-transform: uppercase;
  margin-bottom: 5px;
}


/* Legend: Arrow/Lines */

.thinkingWavelength .legend span.line {
  display: block;
  border-top: 2px solid #532651;
  margin: 10px 0;
  height: 0;
  position: relative;
  width: calc(100% + 47%);
}

.thinkingWavelength .legend span.line:before,
.thinkingWavelength .legend span.line:after {
  content: "";
  position: absolute;
  top: -6px;
  width: 0;
  height: 10px;
  border-color: #532651;
}

.thinkingWavelength .legend span.line:before {
  left: 0;
  border-right-style: solid;
  border-right-width: 2px;
}

.thinkingWavelength .legend span.line:after {
  right: 0;
  border-left-style: solid;
  border-left-width: 2px;
}


/* Legend: Paragraphs */

.thinkingWavelength .legend p {
  color: $grey;
  font-size: 13px;
}


/* Legend: Center Section */

.thinkingWavelength .legend > div:nth-of-type(2) {
  text-align: center;
  position: relative;
}

.thinkingWavelength .legend > div:nth-of-type(2) h2 {
  color: #532651;
}

.thinkingWavelength .legend > div:nth-of-type(2):after {
  content: "";
  position: absolute;
  width: 1px;
  height: calc(100% - 45px);
  left: calc(50% - 1px);
  top: 45px;
  border-right: 1px dashed $greymedium;
}


/* Legend: Right Section */

.thinkingWavelength .legend > div:nth-of-type(3) {
  text-align: right;
}

.thinkingWavelength .legend > div:nth-of-type(3) h2 {
  color: #712049;
}

.thinkingWavelength .legend > div:nth-of-type(3) span.line {
  right: 47%;
}


/* Chart Graphics */

.thinkingWavelength {
  max-height: 800px;
  overflow: hidden;
}

.thinkingWavelength > svg {
  max-width: 100%;
  min-height: 350px;
  .viewer & {
    min-height: 400px;
  }
}

.thinkingWavelength .nameLabel {
  pointer-events: none;
}

.thinkingWavelength svg text {
  font-family: $font-primary;
  font-weight: 600;
}

.editor.lifeplan .thinkingWavelength g.highContributionZone,
.editor.lifeplan .thinkingWavelength g.currentJob {
  cursor: grab;
}

.editor.lifeplan .thinkingWavelength .dragging,
.editor.lifeplan .thinkingWavelength .dragging g.highContributionZone,
.editor.lifeplan .thinkingWavelength .dragging g.currentJob {
  cursor: grabbing;
}

.editor.lifeplan .thinkingWavelength > svg:not(.dragging) g.highContributionZone:hover rect.hczGradient,
.editor.lifeplan .thinkingWavelength > svg:not(.dragging) g.highContributionZone:focus rect.hczGradient {
  fill: $green;
  animation: pulse 3s ease-in-out infinite;
}

.editor.lifeplan .thinkingWavelength g.currentJob:hover path.star,
.editor.lifeplan .thinkingWavelength g.currentJob:focus path.star,
.editor.lifeplan .thinkingWavelength g.currentJob:hover rect.currentJobLabel,
.editor.lifeplan .thinkingWavelength g.currentJob:focus rect.currentJobLabel {
  fill: #00c893;
}

@keyframes pulse {
  0% {
    fill-opacity: 0.25;
  }
  25% {
    fill-opacity: 0.25;
  }
  50% {
    fill-opacity: 0.75;
  }
  75% {
    fill-opacity: 0.25;
  }
  100% {
    fill-opacity: 0.25;
  }
}

.lifeplan .thinkingWavelength g.waves {
  transform: translate(-174px, 00px) scale(0.87);
}

.lifeplan .thinkingWavelength g.waveLabels {
  transform: translate(-64px, -22px) scale(1);
}

.lifeplan .thinkingWavelength .mobile .waves {
  transform: translate(0, 0);
}

.lifeplan .thinkingWavelength .mobile g.waveLabels {
  transform: translate(-64px, -20px);
}

.stratop .thinkingWavelength .mobile .waves {
  transform: translate(0, 600px);
}

.stratop .thinkingWavelength .mobile g.waveLabels {
  transform: translate(-64px, 580px);
}


/* StratOp
----------------------------------------------------*/

.editor.stratop .thinkingWavelength svg {
  cursor: copy;
}

.stratop .thinkingWavelength g.waves {
  //transform: translate(-174px, 115px) scale(0.87);
}

.stratop .thinkingWavelength g.waveLabels {
//  transform: translate(-64px, 90px);
}

.editor.stratop .thinkingWavelength g.currentJob rect.currentJobLabel,
.editor.stratop .thinkingWavelength g.currentJob path.star {
  cursor: grab;
}

.editor.stratop .thinkingWavelength .dragging,
.editor.stratop .thinkingWavelength .dragging g.currentJob rect.currentJobLabel,
.editor.stratop .thinkingWavelength .dragging g.currentJob path.star {
  cursor: grabbing;
}

.editor.stratop .thinkingWavelength g.currentJob:hover rect.currentJobLabel,
.editor.stratop .thinkingWavelength g.currentJob:focus rect.currentJobLabel {
  fill: transparent;
  /* Override */
}

.editor.stratop .thinkingWavelength g.currentJob:hover text.nameLabel,
.editor.stratop .thinkingWavelength g.currentJob:focus text.nameLabel,
.editor.stratop .thinkingWavelength g.currentJob:hover path.star,
.editor.stratop .thinkingWavelength g.currentJob:focus path.star {
  fill: #00C893;
}

.editor.stratop .thinkingWavelength g.delete {
  cursor: default;
}

.editor.stratop .thinkingWavelength g.delete:hover rect.deleteBackground,
.editor.stratop .thinkingWavelength g.delete:focus rect.deleteBackground {
  fill: #ffdacc;
  stroke: $orange;
}

.viewer.stratop .thinkingWavelength g.currentJobs {
  transform: translate(0, -20px);
}


/* Responsive
----------------------------------------------------*/

@include print {
  .thinkingWavelength .currentJobInner {
    transform: scale(0.5);
  }
  .thinkingWavelength .mobile {
    display: none;
  }
}

.thinkingWavelength .mobile {
  display: none;
}

@include small-down {
  @media screen {
    .thinkingWavelength .mobile {
      display: block;
    }
    .thinkingWavelength .desktop {
      display: none;
    }
  }
}

// Mobile Legend

@include small-down {
  @media screen {
    .thinkingWavelength .legend {
      margin-bottom: 10px;
    }

    .thinkingWavelength .legend h2 {
      font-size: 11px;
    }

    .thinkingWavelength .legend span.line {
      width: 95%;
    }

    .thinkingWavelength .legend p {
      font-size: 11px;
    }

    .thinkingWavelength .legend > div {
      flex: 1 1 auto;
      max-width: 50%;
    }

    .thinkingWavelength .legend > div:nth-of-type(2) {
      min-width: 0px;
      max-width: 0px;
    }

    .thinkingWavelength legend > div:nth-of-type(2):after {
      top: 0;
      height: calc(100% + 10px);
    }

    .thinkingWavelength .legend > div:nth-of-type(2) h2 {
      position: absolute;
      top: -50px;
      transform: translate(-50%, 0);
    }

    .thinkingWavelength .legend > div:nth-of-type(3) span.line {
      right: -5%;
    }
  }
}

// Mobile SVG

.thinkingWavelength .mobile .hczGradient {
  transform: scaleY(4.05) translate(0, 10px);
}

.thinkingWavelength .mobile .hczPointer {
  transform: scaleY(4.21) translate(0, 0px);
}

.thinkingWavelength .mobile .hczValue {
  transform: scale(2) translate(0, 250px);
}

.thinkingWavelength .mobile .hczLabel {
  transform: translate(0, 800px);
}

.thinkingWavelength .mobile .hczPointer,
.thinkingWavelength .mobile .hczLabel line {
  stroke-width: 4px;
}

.thinkingWavelength .mobile .hczLabel circle {
  transform: scale(3) translate(0, -140px);
}

.thinkingWavelength .mobile .hczLabel line.hczVertical {
  transform: scaleY(3) translate(0, -140px);
}

.thinkingWavelength .mobile .hczLabel line.hczHorizontal:nth-of-type(1) {
  transform: translate(0, -60px);
}

.thinkingWavelength .mobile .hczLabel line.hczHorizontal:nth-of-type(2) {
  transform: translate(0, 180px);
}

.thinkingWavelength .mobile .hczLabel text {
  font-size: 40px;
}

.thinkingWavelength .mobile .hczLabel text:nth-of-type(1) {
  transform: translate(-283px, 328px) rotate(-90deg);
}

.thinkingWavelength .mobile .hczLabel text:nth-of-type(2) {
  transform: translate(-258px, 328px) rotate(-90deg);
}

.thinkingWavelength .mobile .hczLabel text:nth-of-type(3) {
  transform: translate(-233px, 328px) rotate(-90deg);
}

.thinkingWavelength .mobile .currentJobInner {
  transform: translate(0, 50px);
}

.thinkingWavelength .mobile .avoidLeft,
.thinkingWavelength .mobile .avoidRight {
  transform: translate(0, 860px);
}

.thinkingWavelength .mobile .avoidLeft line,
.thinkingWavelength .mobile .avoidRight line {
  stroke-width: 4px;
}

.thinkingWavelength .mobile .avoidLeft rect,
.thinkingWavelength .mobile .avoidRight rect {
  stroke-width: 80px;
  stroke: white;
}

.thinkingWavelength .mobile .avoidLeft text,
.thinkingWavelength .mobile .avoidRight text {
  font-size: 40px;
  transform: translate(0, 10px);
}

.thinkingWavelength .mobile .scoreBackground {
  transform: translate(-200px, -40px) scale(2, 3);
}

.thinkingWavelength .mobile .score {
  font-size: 44px;
  transform: translate(0, 40px);
}

.thinkingWavelength .mobile .waveLabels text {
  font-size: 40px;
}

.thinkingWavelength .mobile .waveLabels text:nth-of-type(1) {
  transform: translate(0, 155px);
}

.thinkingWavelength .mobile .waveLabels text:nth-of-type(2) {
  transform: translate(0, 290px);
}

.thinkingWavelength .mobile .waveLabels text:nth-of-type(3) {
  transform: translate(0, 422px);
}

.thinkingWavelength .mobile .waveLabels text:nth-of-type(4) {
  transform: translate(0, 560px);
}

.thinkingWavelength .mobile .waveLabels text:nth-of-type(5) {
  transform: translate(0, 695px);
}

.thinkingWavelength .mobile .waves {
  display: block;
}
