@import '../../css/variables',
        '../../css/mixins';

.ourPrimaryCustomer,
.ourPrimaryCustomersValues {
  section {
    margin-bottom: 2rem;
  }
}

.ourPrimaryCustomerInner {

  .editor & {
    display: flex;
    
    @supports (display: grid) {
      display: -ms-grid;
      -ms-grid-template-columns: 1fr 1fr 1fr;
      -ms-grid-column-gap: 2rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 2rem;
    }

    .ourPrimaryCustomersValues & {
      @supports (display: grid) {
        -ms-grid-template-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
      }
    }

    section {
      flex: 1;
      height: 100%;
      
      + section {
        margin-left: 2rem;
        
        @supports (display: grid) {
          margin-left: 0;
        }
      }
    }
  }
  
  h2 {
    @include sectionTitle;
    margin-bottom: 1.5rem;

    .viewer & {
      font-size: 2rem;

      @include large {
        text-align: center;
      }
    }
  }

  .viewer & {
    background-color: transparentize($gold, .8);
    background-repeat: no-repeat;
    background-position: center 0;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;

    @include large {
      clip-path: polygon(0% 0%, 100% 0, 70% 100%, 0% 100%, 30% 100%);
    }

    > section {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0;
      border-top: 4px solid $gold;
      padding: 2rem;

      @include large {
        min-height: 200px;

        &.two {
          div[data-contents] {
            display: block;
            column-count: 2;
          }
        }

        &.many {
          div[data-contents] {
            display: block;
            column-count: 3;
          }
        }
      }
      
      .richTextEditor {
        display: block; // column fix for Safari
      }

      div[data-contents] {
        margin: auto;

        > div {
          padding-right: 3rem;
          margin-bottom: .5rem;
          padding-left: 10px;
          position: relative;
          
          &:before {
            content: "";
            display: block;
            position: absolute;
            border-radius: 5px;
            top: .9rem;
            left: 0px;
            width: 0;
            height: 0;
            border: 2px solid black;
          }
        }
      }

      &:first-of-type {
        @include large {
          padding: 2rem 18% 2rem 18%;
        }
      }

      &:nth-of-type(2) {
        background-color: transparentize($gold, .5);

        @include large {
          padding: 2rem 27%;
        }

        &:last-of-type {
          background-color: transparentize($gold, .25);
        }
      }

      &:nth-of-type(3) {
        background-color: transparentize($gold, .25);
        border-bottom: 4px solid $gold;

        @include large {
          padding: 2rem 40%;

          div[data-contents] {
            column-count: 1;

            > div {
              flex: 0 0 100%;
            }
          }
        }
      }
    }
  }
}
