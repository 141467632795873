/* Charts / Playbook Wrapper
------------------------------------------------------*/
.chartList {
  margin: 0;
  padding: 0;
  list-style: none;
  float: right;
  width: calc(100% - 180px);
  margin-top: calc(#{$meta-height} + 48px);

  @include medium {
    margin-top: $meta-height;
  }

  &.viewer {
    overflow-y: scroll; /* momentum scrolling - has to be scroll, not auto */
    overflow-x: hidden; /* IE */
    -webkit-overflow-scrolling: touch;
    margin-top: 0;
    height: 100vh;
    width: 100%;

    @include medium {
      width: calc(100% - 180px);
    }

    @include print {
      width: 100%;
      overflow: visible;
    }
  }

  .editor & {
    background: $greylight;
    min-height: calc(100vh - #{$meta-height});

    // Chart list
    height: calc(100vh - #{$meta-height});
    overflow-y: scroll; /* momentum scrolling - has to be scroll, not auto */
  }

  .playbookChart.scrolledTo {
    animation: glow 2s 1;
  }
}
