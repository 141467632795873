@import "../../css/_variables";
@import "../../css/_mixins";

.internalWiringWrapper {
  max-width: 100%;
  width: $global-content-width;
  height: 0;
  padding-bottom: 90%;
  position: relative;
  margin-left: auto;
  margin-right: auto;

  @include print {
    margin: 0 auto;
    max-width: 6in;
    width: 6in;
  }
}

.internalWiring {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.internalWiring text {
  pointer-events: none;
  user-select: none;
}

.editor .internalWiring rect.clickable {
  cursor: pointer;
}

.editor .internalWiring rect.clickable:hover,
.editor .internalWiring rect.clickable:focus {
  fill: black;
  fill-opacity: .1;
}

.internalWiring .sectionLabel {
  font-family: $font-secondary;
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;

  @include print {
    font-weight: 700;
    font-size: 40px;
  }
}

.viewer .internalWiring g.hint {
  display: none;
}


/* Print
----------------------------------------------------*/

.internalWiring .labelBackground_Drivers {
  fill: url(#DriverGradient_Print);
}

.internalWiring .labelBackground_ComfortZones {
  fill: url(#ComfortGradient_Print);
}

/* Responsive
----------------------------------------------------*/

@media screen and (max-width: 480px) {
  .internalWiring .text_Drivers { transform: scale(1.5) translate(-50px,-70px); }
  .internalWiring .text_ComfortZones { transform: scale(1.5) translate(-50px,-70px); }

  .internalWiring .text_Contribution { transform: scale(1.5) translate(-150px,-35px); }
  .internalWiring .text_ImagePresentation { transform: scale(1.5) translate(-275px,-35px); }
  .internalWiring .text_PrimaryLeadership { transform: scale(1.5) translate(-150px,-95px); }
  .internalWiring .text_SecondaryLeadership { transform: scale(1.5) translate(-275px,-95px); }

  .internalWiring .text_Things { transform: scale(1.5) translate(-150px,-35px); }
  .internalWiring .text_People { transform: scale(1.5) translate(-275px,-35px); }
  .internalWiring .text_PrimaryIdeas { transform: scale(1.5) translate(-150px,-95px); }
  .internalWiring .text_SecondaryIdeas { transform: scale(1.5) translate(-275px,-95px); }
}
