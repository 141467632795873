@import "../css/_variables";
@import "../css/_mixins";
@import "../css/_animation";

/* Status Indicator
-------------------------------------------------------*/
.statusIndicator {
  box-sizing: content-box;
  border-radius: 50%;
  position: absolute;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  overflow: hidden;
  transition: box-shadow .1s ease;

  .editor &:before {
    content: '';
    transition: transform .25s cubic-bezier(0.140, 0.055, 0.615, 1.190);
    transform: scale(0);
    border-radius: 50%;
    box-shadow: $greymedium 0px 0px 0px;
    background: transparent;
    opacity: 0;
    position: absolute;
    left: calc(50% - 80px);
    top: calc(50% - 80px);
    z-index: 1;
    border: 50px solid $white;
    width: 60px;
    height: 60px;
  }

  .editor & {
    cursor: pointer;
  }

  &.open {
    overflow: visible;
    box-shadow: 0 0 25px 35px $black10;
  }

  /* Status Balls
  -------------------------------------------------------*/
  &.simple-status-null,
  & span.simple-status-null {
    border: 2px solid $greymedium;
    background: $greymediumlight;
  }

  &.simple-status-null,
  span.simple-status-null {
    background-image: url('../image/status-null.svg');
    background-size: 32px;
    background-position: 50%;
  }

  // Green
  &.simple-status-1,
  span.simple-status-1 {
    background-image: url('../image/status-green.svg');
  }

  // Yellow
  &.simple-status-2,
  span.simple-status-2 {
    background-image: url('../image/status-yellow.svg');
  }

  // Red
  &.simple-status-3,
  span.simple-status-3 {
    background-image: url('../image/status-red.svg');
  }


  &:after {
    border-radius: 50%;
    display: block;
    width: 32px;
    height: 32px;
    overflow: hidden;
  }
}


/* Radial Menu Selectors
-------------------------------------------------------*/
.statusIndicator span {
  border-radius: 50%;
  border: 2px solid white;
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 1;
}

.statusIndicator.open span {
  z-index: 5;
  animation-name: scale-in;
  animation-duration: .2s;
}

.statusIndicator.open:before {
  box-shadow: #999 0px 0px 55px;
  transform: scale(1);
  opacity: 1;
}

.statusIndicator span.simple-status-1 {
  top: 0%;
  left: 170%;
}

.statusIndicator span.simple-status-2 {
  top: 170%;
  left: 0;
}

.statusIndicator span.simple-status-3 {
  top: 0%;
  left: -170%;
}

.statusIndicator span.simple-status-null {
  top: -170%;
  left: 0px;
  border: 2px solid transparent;
}


/* Hover States
-------------------------------------------------------*/
.editor .simple-status-1.hover,
.editor .simple-status-1:hover,
.editor .simple-status-1:focus {
  border-color: darken($green, 10%);
}

.editor .simple-status-2.hover,
.editor .simple-status-2:hover,
.editor .simple-status-2:focus {
  border-color: darken($yellow, 10%);
}

.editor .simple-status-3.hover,
.editor .simple-status-3:hover,
.editor .simple-status-3:focus {
  border-color: darken($red, 10%);
}

.editor .simple-status-null.hover,
.editor .simple-status-null:hover,
.editor .simple-status-null:focus {
  border-color: $greymedium;
}

/* View Mode
-------------------------------------------------------*/

.viewer .statusIndicator.simple-status-null {
  visibility: hidden;
}
