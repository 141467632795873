@import "../../css/_variables";
@import "../../css/_mixins";

.talentHeartAssessment {
  @include clearfix;

  section {
    display: block;
    width: 100%;
  }

  .talents,
  .heart {
    flex: 1 0 35%;
    display: block;
    width: 50%;
    border-top: 2px solid $gold;
    border-bottom: 2px solid $gold;
    float: left;
    position: relative;
  }

  .talents-header,
  .heart-header {
    text-transform: uppercase;
    text-align: center;
    margin: 0 auto 2rem auto;
    font-family: $font-secondary;
    font-style: italic;
    font-weight: bold;
    letter-spacing: .1rem;
    color: #353535;
    font-size: 1.6rem;
  }

  .talents-header span,
  .heart-header span {
    border-bottom: 2px solid currentColor;
    display: inline-block;
    padding: 0 0 .1rem 0;
  }

  .talents {
    padding: 3rem 3rem 2rem 0;

    @include print {
      padding: 20px 20px 10px 0;
    }
  }

  .heart {
    padding: 3rem 0 2rem 3rem;
    left: -4px; // Fudge position to force overlap with cross image

    @include print {
      padding: 20px 0 10px 20px;
    }
  }

  .talents > div,
  .heart > div {
    width: 100%;

    .editor & > div {
      min-height: $text-area-min-height-default;
    }

    .editor & {
      margin-bottom: 2rem;
      position: relative;

      &:last-of-type {
        border: none;
        padding: 0;
      }
    }
  }

  .editor & .talents .add,
  .editor & .heart .add {
    cursor: pointer;
    color: $blue;
    border: 2px solid currentColor;
    width: 100%;
    margin: auto 0;
    flex-basis: 100%;
    height: 40px;
  }

  .delete {
    width: 30px;
    height: 30px;
  }

  .editor & .talents .add span.plus,
  .editor & .heart .add span.plus {
    font-weight: 400;
    font-family: inherit;
  }

  .viewer & .talents > div > div:first-of-type,
  .viewer & .heart > div > div:first-of-type {
    margin-bottom: 2.5rem;

    @include print {
      margin-bottom: 15px;
    }
  }

  .viewer & .talents > div span,
  .viewer & .heart > div span {
    font-family: $font-secondary;
    font-weight: 700;
    font-style: italic;
    font-size: 1.6rem;

    @include print {
      font-size: 1.4rem;
    }
  }


  .talents > div .delete,
  .heart > div .delete {
    position: absolute;
    top: 0;
    left: -4rem;
    background-color: transparent;
  }

  .viewer & .talents,
  .viewer & .heart {
    text-align: center;
  }
}

.talent-heart-list {
  margin-bottom: 6rem;
  min-height: 240px;
  position: relative;

  @include print {
    margin-bottom: 0.5in;
  }
}

.talent-heart-x {
  flex: 1;
  position: relative;
  max-width: 144px;

  img {
    position: absolute;
    width: calc(100% + 4px);
    height: 100%;
    top: 0;
    bottom: 0;
    left: -2px;
  }
}

/* Talent List */
.talentHeartAssessment .talent-heart-list {
  display: flex;
}

.talentHeartAssessment .talents > div,
.talentHeartAssessment .heart > div {
  display: flex;
  flex-wrap: wrap;

  .editor & span:first-of-type,
  .editor & input:first-of-type {
    font-weight: 700;
    font-family: $font-secondary;
  }

  > * {
    flex-basis: 100%;

    .editor & {
      margin-bottom: 1rem;
    }
  }
}

/* Clue List */
.talentHeartAssessment .clue-list {
  display: flex;
  flex-wrap: wrap;

  @supports (display: grid) {
    display: -ms-grid;
    -ms-grid-row-gap: 2rem;
    -ms-grid-column-gap: 2rem;
    -ms-grid-template-columns: 1fr;
    display: grid;
    grid-row-gap: 2rem;
    grid-column-gap: 2rem;
    margin-bottom: 4rem;
    grid-template-columns: 1fr;
  }

  @include print {
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    page-break-inside: avoid;

    > * {
      float: left;
      display: inline-block;
      width: 32%;
      margin-left: 2%;
      margin-bottom: 0.25in;

      &:nth-of-type(3n+1) {
        margin-left: 0;
        clear: left;
      }
    }
  }

  @include medium {
    -ms-grid-template-columns: 50% 50%;
    grid-template-columns: 50% 50%;
  }

  @include large {
    -ms-grid-template-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}


.talentHeartAssessment .clue {
  background: #f4f2f1;
  min-height: 140px;
  flex: 1 1 300px;
  margin-right: 2rem;
  margin-bottom: 2rem;
  position: relative;

  @supports (display: grid) {
    margin-right: 0;
    margin-bottom: 0;
  }

  @include print {
    min-height: 0;
  }
}

.talentHeartAssessment .clue {
  .richTextEditor {
    padding: 1rem;

    @include print {
      padding: 8px 10px;
    }
  }
  .clueTextWrap {
    padding: 1rem;

    @include print {
      padding: 0;
    }
  }
}


.talentHeartAssessment .title {
  display: flex;
  background: $greylightwarm;
  border-top: 2px solid $gold;
  border-bottom: 2px solid $gold;
  padding-left: .5rem;
  font-family: $font-primary;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  justify-content: center;
  align-self: center;

  .viewer & {
    height: 40px;
    line-height: 40px;
    justify-content: flex-start;
    padding-left: 10px;

    @include print {
      height: auto;
      line-height: 1.5;
      padding: 5px 10px;
    }
  }
}

.talentHeartAssessment .title {
  input,
  span {
    font-weight: 700;
    flex-basis: 100%;
    font-style: normal;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    background: none;

    .editor & {
      position: relative;
      /* Optically center input field in editor mode */
      padding-right: 4rem;
      border-color: transparent;
      background: none;
    }
  }
}


.talentHeartAssessment .clue-list {
  .delete {
    background-color: transparent;
    align-self: center;
  }

  button.add {
    display: block;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    height: 100%;
    width: 100%;
    border: 2px dotted rgba(0,0,0,.2);
    cursor: pointer;
  }
}

.editor .talentHeartAssessment .clue-list div.richTextEditor {
  height: auto;
  min-height: 120px;
}

// Message List
.talentHeartAssessment .messages {
  display: flex;
  flex-wrap: wrap;

  @supports (display:grid) {
    display: -ms-grid;
    -ms-grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-column-gap: 2rem;
    -ms-grid-row-gap: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
  }

  @include print {
    margin-top: .25in;
    clear: both;
    display: block;
    float: left;
    width: 100%;
    page-break-inside: avoid;

    > div {
      float: left;
      display: inline;
      width: 32%;
      margin-left: 2%;
      margin-bottom: 0.25in;
      page-break-inside: avoid;

      &:nth-of-type(3n+1) {
        margin-left: 0;
        clear: left;
      }
    }
  }

  > div {
    flex: 1 1 30%;
    margin-right: 2rem;
    margin-bottom: 2rem;
    position: relative;
    font-weight: 700;

    @supports (display: grid) {
      margin-right: 0;
      margin-bottom: 0;
    }

    .delete {
      position: absolute;
      top: 4px;
      left: 0;
    }

    textarea {
      min-height: $text-area-min-height-default;
    }

    > div {
      //Message content */
      font-weight: 400;

      &:last-of-type {
        min-height: $text-area-min-height-default;

        @include print {
          min-height: 0;
        }
      }

      @include print {
        margin-bottom: 15px;
      }
    }

    > input, > span {
      width: calc(100% - 4rem);

      .editor & {
        left: 4rem;

        &.plus {
          left: 0;
        }
      }
    }

    input,
    span {
      display: block;
      margin-bottom: 1rem;
      position: relative;
      font-weight: 700;
      text-transform: unset;
      letter-spacing: .05rem;
    }

    div.inlineInput {
      float: right;
      width: calc(100% - 4rem);
    }

    span {
       // Message title
      text-transform: unset;
      font-style: normal;

      @include print {
        margin-bottom: 5px;
      }
    }
  }

  .add {
    position: relative;
    border: 2px dotted rgba(0,0,0,.2);
    top: 0;
    left: 0;
    width: calc(100% - 4rem);
    float: right;
    height: 100%;
    display: block;
    cursor: pointer;
    padding: 2rem;

    &:hover {
      box-shadow: none;
      border: 2px dotted rgba(0,0,0,.4);
    }

    span.plus {
      border: 0;
      display: inline;
    }
  }
}

.talentHeartAssessment .messages-header {
  padding: 1rem;
  font-size: 1.5rem;
  flex-basis: 100%;
  color: $white;
  font-family: $font-secondary;
  font-style: italic;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: .1rem;
  grid-column: 1/4;
  background-image: linear-gradient(-10deg, $blue 0%, $teal 100%);
  margin-bottom: 2rem;

  @supports (display: grid) {
    margin-bottom: 0;
  }

  @include print {
    background: transparent;
    color: $blue;
    border-top: 2px solid $blue;
    border-bottom: 2px solid $blue;
    margin-bottom: 0.25in;
  }
}
