@import "../../css/_variables";
@import "../../css/_mixins";

.opportunityRiskAnalysis  {
  position: relative;
  max-width: 80%;
  margin: 0 auto;

  @include print {
    max-width: 6in;
  }

  .canvas {
    max-width: 1000px;
    margin: 60px auto 0 auto;
    width: 100%;
    user-select: none;
    position: relative;
  }

  .opportunityRiskAnalysisInner  {
    box-sizing: border-box;
    width: 100%;
    position: relative;
    height: 0;
    padding-bottom: 100%;
    overflow: hidden;

    svg {
      width: 100%;
      height: 100%;
      position: absolute;

      text {
        font-family: $font-primary;
        font-weight: 600;
      }
    }
  }

  .item {
    padding-left: 15px;
    position: absolute;
    /* this should be translated such that the marker is at the origin */
  }


  .item:hover,
  .item:focus {
    z-index: 100; /* bring to forefront if there are overlapping .items */
  }

  .delete {
    border-color: #888;
    position: absolute;
    top: -2px;
    left: -2px;
    width: 32px;
    height: 44px;
    z-index: 10;
    border-radius: 4px 0 0 4px;
  }

  .item .shift {
    box-sizing: border-box;
    border-radius: 3px;
    background: white;
    border: 2px solid #888;
    display: block;
    padding-left: 0px;
    min-height: 32px;
    transform: translate(0px, -50%);
    white-space: nowrap;
  }

  .editor & {
    max-width: calc(#{$global-content-width-constrain} - 200px);
    padding-left: 0;
    margin: auto;

    @include xlarge {
      max-width: $global-content-width-constrain;
    }

    .item .shift {
      padding-left: 30px;
    }
  }
}


/* Dot Handle */
.opportunityRiskAnalysis .item .shift .inputWrapper:before {
  content: "";
  position: absolute;
  border-radius: 50%;
  background: #888;
  font-size: 20px;
  border: 5px solid #888;
  width: 0px;
  height: 0px;
  top: calc(50% - 5px);
  left: -21px;
}

.editor .opportunityRiskAnalysis .item .shift .inputWrapper:before {
  cursor: move;
  cursor: grab;
}

/* Dot Handle - Line */
.opportunityRiskAnalysis .item .shift .inputWrapper:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 15px;
  top: calc(50% - 1px);
  left: -15px;
  border-top: 2px solid #888;
}

.editor .opportunityRiskAnalysis .item .shift .inputWrapper:after {
  cursor: move;
  cursor: grab;
}

.opportunityRiskAnalysis .item .shift span {
  border: 0;
  display: block;
  outline: 0;
  outline-offset: 0;
  border-color: transparent;
  border-radius: 0;
  padding: 4px 8px;
}

.editor .opportunityRiskAnalysis .item .shift span {
  cursor: move;
  cursor: grab;
}

.opportunityRiskAnalysis .item .shift input {
  border: 0;
  padding: 0;
  margin: 0;
  padding: 4px 8px;
  height: 28px;
}

.opportunityRiskAnalysis .item .shift .inputWrapper {
  min-width: 35px;
}

.editor .opportunityRiskAnalysis svg {
  cursor: copy;
}

.editor .opportunityRiskAnalysis svg.dragging {
  cursor: move;
  cursor: grabbing;
}

.opportunityRiskAnalysis .item {
  &.highRisk {
      &.lowOpportunity {
      // Foolishness
      .shift,
      .shift .delete,
      .shift .inputWrapper,
      .shift .inputWrapper:before,
      .shift .inputWrapper:after {
        border-color: $orange;
      }
    }

    &.highOpportunity {
      // Entrepreneurial
      .shift,
      .shift .delete,
      .shift .inputWrapper,
      .shift .inputWrapper:before,
      .shift .inputWrapper:after {
        border-color: $green;
      }
    }
  }

  &.lowRisk {
    &.lowOpportunity {
      // Security
      .shift,
      .shift .delete,
      .shift .inputWrapper,
      .shift .inputWrapper:before,
      .shift .inputWrapper:after {
        border-color: $blue;
      }
    }

    &.highOpportunity {
      //Gold Mine
      .shift,
      .shift button.delete,
      .shift .inputWrapper,
      .shift .inputWrapper:before,
      .shift .inputWrapper:after {
        border-color: $gold;
      }
    }
  }
}


/* Axes
----------------------------------------------------*/
div.axis {
  position: absolute;
  width: 100%;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  text-align: center;
}

div.axis:before {
  content: "";
  width: 100%;
  height: 0;
  border-top: 2px solid black;
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
}

div.axisRisk {
  top: 0;
  left: -10px;
  transform-origin: top left;
  transform: rotate(-90deg) translate(-100%,-100%);
  color: $grey;
}

div.axisRisk:before {
  border-color: $grey;
}

div.axisOpportunity {
  top: -40px;
  left: 0;
  color: $teal;
}

div.axisOpportunity:before {
  border-color: $teal;
}

div.axis span {
  display: inline-block;
  position: relative;
  background: white;
}

/* Center Label */
.axis span.label {
  font-family: $font-primary;
  font-weight: 600;
  padding: 0 15px;
  line-height: 34px;
}

.axis span.label:after {
  content: "";
  position: absolute;
  top: calc(50% - 5px);
  right: 0px;
  border-style: solid;
  border-width: 0 2px 0 0;
  width: 0;
  height: 10px;
}

.axis span.label:before {
  content: "";
  position: absolute;
  top: calc(50% - 5px);
  left: 0px;
  border-style: solid;
  border-width: 0 0 0 2px;
  width: 0;
  height: 10px;
}

.axisRisk span:before,
.axisRisk span:after {
  border-color: $grey;
}

.axisOpportunity span:before,
.axisOpportunity span:after {
  border-color: $teal;
}

/* Low Label */
.axis span.low {
  font-family: $font-primary;
  font-weight: 600;
  float: left;
  font-size: 18px;
  line-height: 34px;
  padding-right: 15px;
}

.axis span.low:after {
  content: "";
  position: absolute;
  top: calc(50% - 5px);
  right: 0px;
  border-right-style: solid;
  border-right-width: 2px;
  width: 0;
  height: 10px;
}

/* High Label */
.axis span.high {
  font-family: $font-primary;
  font-weight: 600;
  float: right;
  font-size: 18px;
  line-height: 34px;
  padding-left: 15px;
}

.axis span.high:before,
.axis span.high:after {
  content: "";
  position: absolute;
  left: -3px;
  border-left-style: solid;
  border-left-width: 2px;
  width: 0;
  height: 10px;
}

.axis span.high:before {
  top: calc(50% - 2px);
  transform: rotate(45deg);
}

.axis span.high:after {
  top: calc(50% - 8px);
  transform: rotate(-45deg);
}


// Responsive
.opportunityRiskAnalysis .small {
  display: none;
}

@media screen and (max-width: 480px) {

  .opportunityRiskAnalysis .axis {
    display: none;
  }

  .opportunityRiskAnalysis svg path {
    stroke-width: 8;
  }

  .opportunityRiskAnalysis svg text {
    font-size: 44px;
  }

  .opportunityRiskAnalysis .item .shift {
    min-height: 22px;
  }

  .opportunityRiskAnalysis .item .shift span {
    font-size: 11px;
    padding: 1px 4px;
  }

  .viewer .opportunityRiskAnalysis div.canvas,
  .viewer .opportunityRiskAnalysis div.item {
    display: none;
  }

  .viewer .opportunityRiskAnalysis div.small {
    display: block;
  }

  .viewer .opportunityRiskAnalysis div.small div {
    margin: 0 0 25px 0;
  }

  .viewer .opportunityRiskAnalysis div.small div h2 {
    color: $greymedium;
    font-size: 20px;
    text-transform: uppercase;
    text-align: center;
  }

  .viewer .opportunityRiskAnalysis div.small div h3 {
    color: $greymedium;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
  }

  .viewer .opportunityRiskAnalysis div.small div ul {
    padding: 0;
    list-style: none;
  }

  .viewer .opportunityRiskAnalysis div.small div ul li {
    border: 2px solid $black20;
    border-radius: 4px;
    padding: 4px 8px;
    text-align: center;
    margin: 5px 0 0 0;
    background: $black10;
  }

  /* Foolishness */
  .viewer .opportunityRiskAnalysis div.small div.highRisk.lowReward h2 {
    color: $orange;
  }
  .viewer .opportunityRiskAnalysis div.small div.highRisk.lowReward ul li {
    border-color: rgba(255, 69, 0, 0.5);
    background: rgba(255, 69, 0, 0.15);
  }

  /* Entrepreneurial */
  .viewer .opportunityRiskAnalysis div.small div.highRisk.highReward h2 {
    color: $green;
  }
  .viewer .opportunityRiskAnalysis div.small div.highRisk.highReward ul li {
    border-color: rgba(0, 162, 117,0.5);
    background: rgba(0, 162, 117,0.2);
  }

  /* Security */
  .viewer .opportunityRiskAnalysis div.small div.lowRisk.lowReward h2 {
    color: $blue;
  }
  .viewer .opportunityRiskAnalysis div.small div.lowRisk.lowReward ul li {
    border-color: rgba(16, 39, 119, 0.5);
    background: rgba(16, 39, 119, 0.15);
  }

  /* Gold Mine */
  .viewer .opportunityRiskAnalysis div.small div.lowRisk.highReward h2 {
    color: $gold;
  }
  .viewer .opportunityRiskAnalysis div.small div.lowRisk.highReward ul li {
    border-color: rgba(204, 174, 83, 0.5);
    background: rgba(204, 174, 83, 0.2);
  }

}