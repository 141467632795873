@import "../../css/_variables";
@import "../../css/_mixins";

.viewer .vocationalGating thead th span {
  margin: 0;
}

.vocationalGating thead th {
  padding: 0 0 1rem 0;
}
.vocationalGating tr.gateHeader th,
.vocationalGating tr.gateHeader td {
  background-color: $greylightwarm;
  font-family: 'essonnes-headline', serif;
  font-style: italic;
  font-weight: 700;
  font-size: 18px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.vocationalGating tr.spacer td,
.vocationalGating tbody tr.spacer:last-of-type td {
  border: 0;
  height: 30px;
  background: white;
}

.vocationalGating tbody tr:last-of-type td,
.vocationalGating tbody tr:last-of-type th {
  background-color: white;
}

.vocationalGating tbody td div {
  display: block;
  position: relative;
}

.vocationalGating tbody td div button.delete {
  position: absolute;
  top: calc(50% - 14px);
  left: -55px;
}

.vocationalGating tbody td div input,
.vocationalGating tbody td div:not(.statusIndicator) span {
  box-sizing: border-box;
  background: transparent;
  border: 1px solid rgba(0,0,0,0.2);
  color: $grey;
  display: block;
  font-family: 'Manifold', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  height: auto;
  line-height: 20px;
  margin: 0;
  width: 100%;
  outline: 0;
  outline-offset: 0;
  padding: 6px;
  text-align: left;
  text-transform: none;
}

.vocationalGating tbody td div:not(.statusIndicator) span {
  border: 1px solid transparent;
}

.vocationalGating tbody td div:not(.statusIndicator) span.blank {
  font-weight: normal;
  font-style: italic;
  opacity: 0.5;
}

.editor .vocationalGating tbody td div:not(.statusIndicator) span {
  cursor: text;
}

.vocationalGating tbody tr.add td {
  @include striped;
}

.viewer .vocationalGating tbody tr.add {
  display: none;
}

.vocationalGating tr.gateHeader th,
.vocationalGating tr.gateHeader td {
  font-size: 14px;
  border-bottom: 2px solid $gold;
}

.vocationalGating tbody tr:nth-last-child(3) td {
  border-bottom: 2px solid $gold;
}

table.vocationalGating > button {
  margin: 0 0 1rem 0;
}


@media screen and (max-width: 480px) {

  /* Vocational Gating-Only Responsive Actions */
  .filterTable.vocationalGating {
    display: block;
    border-spacing: 0;
  }

  .filterTable.vocationalGating thead {
    display: none;
  }

  .filterTable.vocationalGating tr {
    display: block;
    float: left;
    width: 100%;
  }

  .filterTable.vocationalGating td {
    display: block;
    float: left;
    text-align: left;
    width: 100%;
  }

  .filterTable.vocationalGating tr.gateHeader td {
    display: none;
  }

  .filterTable.vocationalGating tr.spacer td:first-of-type {
    background: transparent;
    border: 0;
  }

  .filterTable.vocationalGating td .statusIndicator {
    float: right;
  }

  .filterTable.vocationalGating td:first-of-type {
    display: block;
    float: left;
    width: 100%;
  }

  .filterTable.vocationalGating td,
  .filterTable.vocationalGating tbody tr:first-of-type td {
    border: 1px solid rgba(0,0,0,0.1);
    border-width: 1px 0 0 0;
    position: relative;
  }

  .filterTable.vocationalGating tr td:first-of-type,
  .filterTable.vocationalGating tbody tr:first-of-type td:first-of-type {
    border-top: solid 2px $gold;
    border-bottom: solid 2px $gold;
    background-color: $greylightwarm;
  }

  .filterTable.vocationalGating tr td:first-of-type + td {
    border-top: 0;
  }

  .filterTable.vocationalGating tbody tr:last-of-type td {
    border-bottom: 0;
  }

  .filterTable.vocationalGating td:before {
    content: attr(data-title);
    display: inline-block;
    text-align: left;
    margin-top: 7px;
    max-width: calc(80vw - 60px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
    word-wrap: break-word;
  }

  .filterTable.vocationalGating tfoot tr {
    border: 2px solid $gold;
  }

  .filterTable.vocationalGating tfoot td:first-of-type {
    border-top: 0;
  }

  .filterTable.vocationalGating tfoot td:before {
    font-weight: 700;
  }

  .vocationalGating tr.gateHeader th {
    background: $gold;
    display: block;
    width: 100%;
  }

  .vocationalGating tr.gateHeader th:empty {
    display: none;
  }

  .vocationalGating tr.spacer {
    margin: 2px 0;
  }

  .vocationalGating tbody tr:nth-last-child(3) td {
    border-bottom: 0;
  }

  .vocationalGating tbody tr.results {
    border: 2px solid $gold;
  }

  .vocationalGating tbody tr.results td:first-of-type {
    background-color: $greylightwarm;
    border-top: 0;
  }

  .vocationalGating tbody tr.results td:before {
    font-weight: 700;
  }

}