// Document Outline Column
.outline {
  background: $greylight;
  list-style: none;
  margin: 0;
  padding: 0;
  border-right: 2px solid $gold;
  position: fixed;
  left: 0;
  width: 180px;
  height: calc(100% - #{$meta-height} - 48px);
  overflow: auto;
  top: calc(#{$meta-height} + 48px);

  @include medium {
    top: $meta-height;
    height: calc(100% - #{$meta-height});

    .viewerActive + &,
    &:first-child {
      top: $nav-height;
      height: calc(100% - #{$nav-height});
    }
  }

  @include print {
    display: none !important;
  }

  li {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid $greymedium;

    &:last-child {
      box-shadow: rgba(black, .15) 0 1px 4px;
    }

    a {
      background: white;
      border-radius: 0;
      border: 0;
      cursor: pointer;
      display: block;
      font-size: 12px;
      padding: 8px;
      width: 100%;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      i {
        cursor: grab;
        float: left;
        display: inline-block;
        font-style: normal;
        text-align: center;
        margin-right: 6px;
        width: 18px;
        vertical-align: top;

        &:before {
          content: '';
          display: block;
          width: 18px;
          height: 18px;
          background: url('../../image/icon-move.svg') no-repeat center;
          background-size: contain;
          cursor: grab;
        }
      }

      &:hover {
        box-shadow: transparent 0 0 0 0;
        background: rgba($blue, .075);
      }
    }
  }
}

.playbookMeta:not(.viewerActive) + .outline {
  li {
    &.locked {
      cursor: not-allowed;
      display: block;

      &:hover {
        background: transparent;
      }

      a {
        cursor: not-allowed;

        &:hover, &:focus {
          background: white;
        }

        i {
          cursor: not-allowed;

          &:before {
            background-image: url('../../image/icon-lock.svg');
            cursor: not-allowed;
          }
        }
      }
    }

    &.isDragging {
      display: none;
    }

    &.isOver.hoverAbove:before,
    &.isOver.hoverBelow:after {
      content: '';
      border-bottom: 1px solid $green;
      box-shadow: inset $black15 0 0 8px;
      display: block;
      width: 100%;
      background: $greymediumlight;
      height: 34px;
    }
  }
}

.playbookMeta.viewerActive + .outline {
  display: none;

  @include medium {
    display: block;
    top: $nav-height;
    height: calc(100% - #{$nav-height});
  }

  li {
    a {
      padding: 8px 8px 8px 12px;

      i {
        display: none;
      }
    }
  }
}
