@import "../../css/_variables";
@import "../../css/_mixins";

.filterTable {
  border-spacing: 10px 0;
  border-collapse: separate;
  table-layout: fixed;
  font-family: $font-primary;
  font-weight: 600;

  thead th {
    vertical-align: top;
    border-top: 0;
    border-bottom: 0;
    text-transform: none;
    text-align: center;
    font-weight: 600;
    padding-left: 0;
    padding-right: 0;

    &:last-child {
      vertical-align: bottom;
    }
  }

  tbody {
    tr:first-of-type td,
    tr:first-of-type th {
      border-top: 2px solid $gold;
    }
  }

  .editor & {
    th > div span {
      cursor: text;
    }

    th > div input {
      text-align: center;
    }
  }

  button.delete {
    width: 30px;
    height: 30px;
    display: block;
    margin: 0 auto;
  }

  thead th.add {
    text-align: center;
    width: 60px;
    padding-right: 0;
    padding-left: 0;
  }

  td {
    text-align: center;
    vertical-align: middle;
  }

  td.add {
    @include striped;
    padding-right: 0;
    padding-left: 0;
    width: 60px;
  }

  .viewer & {
    th.add,
    td.add {
      display: none;
    }
  }

  tbody tr.add td,
  tbody tr:last-of-type td {
    border-bottom: 2px solid $gold;
  }

  tfoot td span {
    color: $red;
    display: block;
  }

  tfoot td.add {
    background-color: white;
  }

  td:first-of-type {
    text-align: left;
    font-weight: 600;
    text-transform: uppercase;
    width: 200px;
  }

  .statusIndicator {
    position: relative;
    display: inline-block;
  }

  button.add {
    height: 30px;
    min-width: 30px;
  }
}

@media screen and (max-width: 480px) {
  .filterTable tr th,
  .filterTable tr td {
    padding: 6px 8px;
    max-width: 100%;
  }

  /* Life Perspective Filter-Only Responsive Actions */
  .filterTable.lifePerspectiveFilter {
    display: block;
    border-spacing: 0;
  }

  .filterTable.lifePerspectiveFilter thead {
    display: none;
  }

  .filterTable.lifePerspectiveFilter tr {
    display: block;
    float: left;
    width: 100%;
  }

  .filterTable.lifePerspectiveFilter td {
    display: block;
    float: left;
    text-align: left;
    width: 100%;
  }

  .filterTable.lifePerspectiveFilter td .statusIndicator {
    float: right;
  }

  .filterTable.lifePerspectiveFilter td:first-of-type {
    display: block;
    float: left;
    width: 100%;
  }

  .filterTable.lifePerspectiveFilter td,
  .filterTable.lifePerspectiveFilter tbody tr:first-of-type td {
    border: 1px solid $black10;
    border-width: 1px 0 0 0;
    position: relative;
  }

  .filterTable.lifePerspectiveFilter tr td:first-of-type,
  .filterTable.lifePerspectiveFilter tbody tr:first-of-type td:first-of-type {
    border-top: solid 2px $gold;
    border-bottom: solid 2px $gold;
    background-color: $greylightwarm;
  }

  .filterTable.lifePerspectiveFilter tr td:first-of-type + td {
    border-top: 0;
  }

  .filterTable.lifePerspectiveFilter tbody tr:last-of-type td {
    border-bottom: 0;
  }

  .filterTable.lifePerspectiveFilter td:before {
    content: attr(data-title);
    display: inline-block;
    text-align: left;
    margin-top: 7px;
    max-width: calc(80vw - 60px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
    word-wrap: break-word;
  }

  .filterTable.lifePerspectiveFilter tfoot tr {
    border: 2px solid $gold;
  }

  .filterTable.lifePerspectiveFilter tfoot td:first-of-type {
    border-top: 0;
  }

  .filterTable.lifePerspectiveFilter tfoot td:before {
    font-weight: 600;
  }
}
