/* Header
------------------------------------------------------*/

nav {
  font-family: $font-primary;
  background: $grey;
  height: 48px;
  position: fixed;
  top: 0;
  left: 0;
  padding: 5px 10px;
  width: 100%;
  z-index: 100;

  > button {
    padding-right: .25rem;
  }

  .logoutLink {
    padding-right: .25rem;
  }

  .changePasswordLink, .helpLink {
    color: $white;
    font-size: 15px;
    line-height: 38px;
    text-decoration: none;
    float: right;
    font-weight: 500;

    &:after {
      content: '|';
      font-weight: 400;
      display: inline-block;
      padding-left: 1.25rem;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .helpLink {
    margin-right: 14px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: right;
  }

  button,
  .logoutLink {
    color: white;
    float: right;
    border: 0px;
    line-height: 1.6;

    &:after {
      display: none;
    }

    &:hover {
      box-shadow: transparent 0 0 0;
      background: transparent;
      text-decoration: underline;
      border: 0;
      cursor: pointer;
    }

    &.back {
      position: relative;

      &:before,
      &:after {
        content: '';
        width: 6px;
        height: 2px;
        background: white;
        position: absolute;
        left: 3px;
      }

      &:before {
        transform: rotate(45deg);
        top: 19px;
      }

      &:after {
        transform: rotate(-45deg);
        top: 16px;
      }
    }
  }
}

.navLogo {
  position: absolute;
  top: 12px;
  left: 12px;
  max-height: 24px;
  width: auto;

  @include small-down {
    display: none;
  }
}

.navIcon {
  display: none;
  position: absolute;
  top: 12px;
  left: 12px;
  max-height: 24px;
  width: auto;

  @include small-down {
    display: block;
  }
}

@include medium-down {
  .content .playbookMeta.viewerActive a.edit,
  nav a.logoutLink,
  nav a.changePasswordLink {
    font-size: 12px;
    font-weight: normal;
    line-height: 38px;
    padding: 0;
  }

  nav a.changePasswordLink:after {
    padding-left: 6px;
    font-weight: normal;
  }

  nav a.logoutLink {
    padding-left: 6px;
  }
}