@import "../../css/_variables";
@import "../../css/_mixins";
.ourMission {
  &.chart {
    .viewer & {
      padding-bottom: 0;
    }
  }

  .inlineInput {
    display: block;
  }

  .inlineInput,
  .ourMissionStatement {
    text-align: center;
    font-family: $font-secondary;
    max-width: 42rem;
    font-weight: 400;
    margin: auto;
  }

  .ourMissionStatement {
    font-size: 2rem;
  }
}