@import "../../css/_variables";
@import "../../css/_mixins";

.strategicDashboard,
.myLifeDashboard {

  .mobile {
    cursor: move;
  }

  .slick-slider {
    .slick-list {
      padding: 0 20px !important;
    }
  }

  .direction,
  .vitalSigns,
  .performanceModel,
  .performanceDrivers {
    @include medium {
      margin-left: 3rem;
    }
  }

  .risks,
  .direction,
  .performanceModel,
  .performanceDrivers,
  .vitalSigns {
    @include medium {
      flex: 1;
      min-width: 0;
    }

    h2 {
      border-top: 2px solid $gold;
      border-bottom: 2px solid $gold;
      display: block;
      text-transform: uppercase;
      min-height: 32px;
      font-family: $font-secondary;
      line-height: 1.6;
      padding: 4px 8px;
      background: $goldlight;

      + span {
        display: flex;
        padding: .5rem .5rem .5rem .5rem;
        justify-content: center;
        font-family: $font-primary;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: .02em;
        background-color: $greylight;
      }
    }

    > ul {
      list-style-type: none;
      margin: 0;
    }

    > ul > li {
      display: flex;
      align-items: center;
      padding: 2rem 0;
    }

    .delete {
      flex: 0 0 32px;
    }

    .add {
      display: block;
      margin: auto;
    }

    .statusIndicator {
      position: relative;
      display: inline-block;

      &.status-null {
        visibility: visible;
      }
    }
  }

  // Risks section
  .risksInner {
    > ul > li {

      .viewer &,
      .planOnAPage-Poster & {
        overflow: hidden;
      }

      .editor & {
        &:not(:first-of-type) {
          padding: 10px 0 0 0;
        }
      }
    }

    li > span {
      order: 0;

      .viewer &,
      .planOnAPage-Poster & {
        margin: 0 1rem;
      }

      &:first-of-type:not(.inlineInput) {
        // List number
        font-size: 28px;
        flex: 0 0 40px;
        color: $gold;
        letter-spacing: 1.4px;
      }
    }

    .statusIndicator {
      flex: 0 0 auto;
      margin: 0 1rem 0 0;
      order: 0;
    }
  }

  // Direction section
  .direction,
  .performanceModel {
    flex-wrap: wrap;
    align-items: flex-start;

    > ul {
      margin: 2rem 0;
    }

    > ul > li {
      border-bottom: 2px solid $greylight;
      padding-bottom: 0;
      flex-wrap: wrap;
      align-items: flex-start;

      &:first-of-type {
        padding-top: 0;
      }

      .viewer &,
      .planOnAPage-Poster & {
        display: block;

        > * {
          display: block;
        }
      }

      input,
      .blank,
      textarea,
      span,
      > div {
        flex: 1;
        margin-bottom: 1rem;
      }

      > span {
        font-weight: 700;
      }

      textarea {
        max-height: 40px;
      }

      .delete {
        margin-bottom: 1rem;
      }
    }

    .editor & .richTextEditor {
      border: 1px solid $input-border-default;
      border-radius: 2px;
      flex: 1 1 100%;
      margin-left: 32px;
      margin-bottom: 2rem;
      min-height: 70px;
    }
  }
}


// Status Indicators
.vitalSigns,
.performanceDrivers {

  .statusAndTrendIndicator {
    &.status-1-1,
    span.status-1-1 {
      background-image: url('../../image/status-arrows-horizontal/green-horizontal-left.svg');
    }

    &.status-1-2,
    span.status-1-2 {
      background-image: url('../../image/status-arrows-horizontal/green-horizontal-flat.svg');
    }

    &.status-1-3,
    span.status-1-3 {
      background-image: url('../../image/status-arrows-horizontal/green-horizontal-right.svg');
    }

    &.status-2-1,
    span.status-2-1 {
      background-image: url('../../image/status-arrows-horizontal/yellow-horizontal-left.svg');
    }

    &.status-2-2,
    span.status-2-2 {
      background-image: url('../../image/status-arrows-horizontal/yellow-horizontal-flat.svg');
    }

    &.status-2-3,
    span.status-2-3 {
      background-image: url('../../image/status-arrows-horizontal/yellow-horizontal-right.svg');
    }

    &.status-3-1,
    span.status-3-1 {
      background-image: url('../../image/status-arrows-horizontal/red-horizontal-left.svg');
    }

    &.status-3-2,
    span.status-3-2 {
      background-image: url('../../image/status-arrows-horizontal/red-horizontal-flat.svg');
    }

    &.status-3-3,
    span.status-3-3 {
      background-image: url('../../image/status-arrows-horizontal/red-horizontal-right.svg');
    }
  }
}

.risks {

  .statusAndTrendIndicator {
    width: 50px;

    &.status-1-1,
    span.status-1-1 {
      background-image: url('../../image/status-arrows-horizontal/green-horizontal-right.svg');
    }

    &.status-1-2,
    span.status-1-2 {
      background-image: url('../../image/status-arrows-horizontal/green-horizontal-flat.svg');
    }

    &.status-1-3,
    span.status-1-3 {
      background-image: url('../../image/status-arrows-horizontal/green-horizontal-left.svg');
    }

    &.status-2-1,
    span.status-2-1 {
      background-image: url('../../image/status-arrows-horizontal/yellow-horizontal-right.svg');
    }

    &.status-2-2,
    span.status-2-2 {
      background-image: url('../../image/status-arrows-horizontal/yellow-horizontal-flat.svg');
    }

    &.status-2-3,
    span.status-2-3 {
      background-image: url('../../image/status-arrows-horizontal/yellow-horizontal-left.svg');
    }

    &.status-3-1,
    span.status-3-1 {
      background-image: url('../../image/status-arrows-horizontal/red-horizontal-right.svg');
    }

    &.status-3-2,
    span.status-3-2 {
      background-image: url('../../image/status-arrows-horizontal/red-horizontal-flat.svg');
    }

    &.status-3-3,
    span.status-3-3 {
      background-image: url('../../image/status-arrows-horizontal/red-horizontal-left.svg');
    }
  }
}

// Slider Charts
.strategicDashboard .risks,
.strategicDashboard .performanceDrivers,
.myLifeDashboard .vitalSigns {

  li {
    border-bottom: 2px solid $greylight;
    flex-wrap: wrap;
    page-break-inside: avoid;

    .delete {
      order: 1;
      align-self: start;
    }

    > div.statusSlider {
      order: 2;
      flex: 1;
      display: flex;
      flex-wrap: wrap;

      /* status indicator */
      > div.statusIndicator {
        margin: 4px 10px 0 0;
        flex: 0 0 32px;
        order: 1;
      }

      /* input */
      > span:first-of-type,
      > input:first-of-type {
        font-size: 14px;
        font-weight: normal;
        color: black;
        letter-spacing: 0;
        flex: 1 1 0%;
        order: 2;
      }

      /* slider */
      label {
        margin: 1rem 0;
        display: flex;
        flex: 1 1 100%;
        margin-left: 45px;
        align-items: center;
        order: 3;

        > span.title {
          flex: 0 1 0%;
          font-size: 13px;
          font-weight: bold;
          color: $gold;
        }

        > input {
          flex: 1 1 100%;
          margin-left: 15px;
          min-width: 0;
        }

        > span.value {
          color: $gold;
          flex: 0 0 36px;
          text-align: center;
          font-weight: bold;
        }
      }
    }
  }
}

.viewer,
.planOnAPage-Poster {

  .risksInner {
    min-height: 240px;
    position: relative;
    padding-left: 15px;

    .axisGroup {
      position: absolute;
      width: 20px;
      height: calc(100% - 20px);
      top: 10px;
      left: 2px;
      z-index: 1;

      div.axisLine {
        position: absolute;
        width: 0;
        height: 100%;
        top: 0;
        left: 1px;
        border-left: 2px solid $greymedium;
      }

      div.axisLabel {
        background: white;
        display: block;
        position: absolute;
        left: calc(100% / 12);
        font-size: 11px;
        line-height: 20px;
        font-family: $font-primary;
        font-weight: bold;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $gold;
        width: 100px;
        transform-origin: top left;
        z-index: 10;

        &:before, &:after {
          content: "";
          position: absolute;
          border-top: 2px solid $greymedium;
          width: 6px;
          height: 0px;
        }

        &.high {
          text-align: right;
          transform: rotate(-90deg) translate(-100px,-10px);

          &:before {
            left: -6px;
            top: calc(50% - 1px);
            z-index: 10;
            transform-origin: right bottom;
            transform: rotate(45deg);
          }

          &:after {
            top: 50%;
            left: -6px;
            top: calc(50% - 1px);
            z-index: 10;
            transform-origin: right top;
            transform: rotate(-45deg);
          }
        }

        &.low {
          transform: rotate(-90deg) translate(-20px,-10px);
          text-align: left;
          top: auto;
          bottom: 0;

          &:before {
            right: -6px;
            top: calc(50% - 1px);
            z-index: 10;
            transform-origin: left bottom;
            transform: rotate(-45deg);
          }

          &:after {
            top: 50%;
            right: -6px;
            top: calc(50% - 1px);
            z-index: 10;
            transform-origin: left top;
            transform: rotate(45deg);
          }
        }
      }
    }
  }

  .vitalSigns ul,
  ul.horizontalDrivers {
    margin: 0;

    li {
      padding: 0.5rem 0;

      > div {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        padding: 5px 0;
        margin: 0;
        page-break-inside: avoid;
      }

      div.lineWrap {
        align-self: center;
        flex: 0 0 45%;
        order: 1;
        position: relative;
        min-width: 0;
        margin: 0 20px;
        width: auto;

        div.line {
          transform: translate(-20px,0);
          min-height: 50px;
          width: calc(100% + 40px);

          > span {
            position: absolute;
            top: 15px;
            height: 0;
          }

          span.dashed {
            border-top: 1px dashed $gold;
            left: 4px;
            width: calc(100% - 8px);

            &:before, &:after {
              content: "";
              display: block;
              position: absolute;
              top: -6px;
              height: 11px;
              width: 0px;
            }

            &:before {
              left: -4px;
              border-right: 2px solid $gold;
            }

            &:after {
              right: -4px;
              border-left: 2px solid $gold;
            }
          }

          span.solid {
            border-top: 3px solid $gold;
            right: 0;
            top: 14px;
          }
        }

        div.marker {
          margin: 0;
          background: white;
          transform: translate(-18px,0);
          position: absolute;
          top: 0;
          width: 36px;

          .statusIndicator {
            margin: 0;
            display: block;
            text-align: center;
            width: 100%;

            + span {
              background: white;
              margin: 0;
              margin-top: 0;
              font-size: 12px;
              line-height: 16px;
              font-weight: bold;
              display: block;
              text-align: center;
              position: relative;
              z-index: 2;
            }
          }
        }
      }

      div.label {
        margin: 8px 0 8px 10px;
        align-self: start;
        flex: 1 1 auto;
        order: 2;
        width: auto;
        font-size: 12px;
        font-weight: 600;
      }
    }

    &.reverse {
      li {
        div.lineWrap {
          order: 2;

          div.line {
            span.dashed {
              &:before {
                border-right: 2px solid $gold;
              }

              &:after {
                border-left: 1px solid $gold;
              }
            }

            span.solid {
              right: auto;
              left: 0;
            }
          }
          div.marker {
            left: auto;
            transform: translate(-18px,0);
          }
        }
        div.label {
          order: 1;
          margin: 8px 10px 8px 0;
          text-align: right;
        }
      }
    }
  }
}

.myLifeDashboard .myLifeDashboardInner > div > div,
.strategicDashboard .strategicDashboardInner > div > div {

  &.mobile {
    display: none;

    div > section {
      margin: 0 10px;
    }
  }
}

.myLifeDashboard .myLifeDashboardInner > div > div,
.strategicDashboard .strategicDashboardInner > div > div {

  @media screen and (min-width: 841px) {
    &.desktop {
      display: flex;
    }
  }

  @media screen and (max-width: 840px) {
    &.mobile {
      display: block;
    }
    &.desktop {
      display: none;
    }
  }

  @include print {
    &.mobile {
      display: none;
    }
    &.desktop {
      @include clearfix;
      display: block;

      > section {
        float: left;
        width: 32%;
        margin-left: 2%;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

.editor {
  .strategicDashboard .strategicDashboardInner {
    display: flex;
  }
  .myLifeDashboard .myLifeDashboardInner > div > div.desktop {
    display: flex;
  }
}

/*
.viewer {
  .myLifeDashboard .myLifeDashboardInner > div > div,
  .strategicDashboard .strategicDashboardInner > div > div {

    @media screen and (min-width: 841px) {
      &.desktop {
        display: flex;
      }
    }

    @media screen and (max-width: 840px) {
      &.mobile {
        display: block;
      }
      &.desktop {
        display: none;
      }
    }

    @include print {
      &.mobile {
        display: none;
      }
      &.desktop {
        @include clearfix;
        display: block;

        > section {
          float: left;
          width: 32%;
          margin-left: 2%;

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}

.editor {
  .myLifeDashboard .myLifeDashboardInner,
  .strategicDashboard .strategicDashboardInner {
    display: flex;

    > .performanceDrivers {
      > ul {
        margin: 2rem 0;
      }
    }
  }
}
*/