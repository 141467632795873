
// Datepicker component
.SingleDatePickerInput {
  min-height: 32px;
  display: inline-block;
  border-radius: 2px;
}

.DateInput {
  font-size: 14px;
  padding: 0;
  margin-bottom: 0;

  &__display-text {
    border-radius: 2px;
    min-height: 32px;
    background-color: $white;
    color: $grey;
  }

  > input {
    margin-bottom: 0;
    line-height: 1;
    min-height: 0;
  }
}

.DayPicker__week-header {
  small {
    color: #777;
  }
}

.facilitationDateWrap {
  margin-bottom: 2rem;

  .SingleDatePicker {
    margin-left: 0;
    display: block;
    margin-top: 1rem;

    &__picker {
      transform: translateY(-25px);
    }
  }

  .DateInput--with-caret:after {
    top: 36px;
  }

  .DateInput--with-caret:before {
    top: 35px;
  }
}

.SingleDatePicker {
  min-height: 32px;
  margin-left: 1rem;
  border-color: transparent;

  .transition-container {
    min-height: 370px;
  }

  &__picker {
    min-height: 370px;
    box-shadow: 0 10px 30px $black10, 0 0 2px $black15;
  }

  table {
    width: auto;
    margin: unset;

    td {
      vertical-align: middle;
      background-color: $white;
      text-align: center;
      transition: background-color .2s ease;
      border-color: transparent;

      &.CalendarDay--today {
        position: relative;

        &:hover {
          color: $grey;
          background-color: transparent;
        }

        &:hover:before {
          display: none;
        }

        &:before {
          content: '';
          position: absolute;
          top: 5%;
          width: 90%;
          height: 90%;
          min-height: 34px;
          left: 11%;
          margin-left: -2.5px;
          background-color: $goldlight;
          border-radius: 50%;
          opacity: .3;
          mix-blend-mode: multiply;
        }
      }

      &.CalendarDay--selected {
        color: $gold;
        font-weight: 700;
      }

      &.CalendarDay--valid:hover {
        background-color: $gold;
        color: $white;
      }
    }
  }
}
