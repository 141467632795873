@import "../css/_variables";
@import "../css/_mixins";
@import "../css/_animation";

div.playbookMeta div.share {
	font-family: $font-primary;
  padding: 0;

  > div {
    padding: 15px;
    overflow: auto;
    max-height: 60vh;

    &.loading {
      padding: 45px 15px;
    }

    &.saving {
      cursor: default;
      pointer-events: none;

      > * {
        opacity: 0.25;
      }
    }

    &.loading::before,
    &.saving::before {
      border: solid 3px rgba($teal,0.25);
      border-bottom-color: $teal;
      border-radius: 50%;
      content: "";
      height: 40px;
      left: calc(50% - 20px);
      opacity: inherit;
      position: absolute;
      top: calc(50% - 20px);
      transform-origin: center;
      width: 40px;
      will-change: transform;
      z-index: 100;
      animation: spin 1.5s linear infinite;
    }
  }

	form {
    background-color: lighten($goldlight, 15%);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
		padding: 15px 59px 15px 15px;
		margin: 0;
		display: flex;
		align-items: center;

		&::after {
			display: none;
		}

		input {
			font-family: $font-primary;
			flex: 1 1 auto;
			margin-right: 4px;
			max-height: 32px;
			min-height: 0;
		}

		button:not(.close):not(.delete) {
			flex: 0 0 80px;
			max-height: 32px;
			min-height: 32px;
      line-height: 26px;
		}
	}

  h2 {
    padding: 5px 0;
    text-align: left;
  }

	ul {
		list-style: none;
		margin: 0;
		padding: 0 44px 60px 0;

		li {
			padding: 8px 0;
			border-bottom: 1px solid $greymedium;

      &:last-child {
        border-bottom: 0;
      }

			div.permissionEditor,
      div.permissionViewer {
				display: flex;
				align-items: center;
        position: relative;

				span.email {
          flex: 0 1 241px;
          text-align: left;
          max-width: 241px;

          span.textwrap {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            display: block;
          }
				}

        span.sent,
        span.status {
					flex: 0 0 80px;
          background: rgba($green,0.2);
          border: 0;
          color: $green;
          font-weight: bold;
          text-align: center;
          height: 32px;
          line-height: 34px;
          border-radius: 2px;
          font-size: 13px;
        }

        span.sent {
          margin-left: 5px;
          &:after {
            content: "";
            display: inline-block;
            vertical-align: -3px;
            opacity: 1;
            margin-left: 4px;
            width: 17px;
            height: 17px;
            background: url('../image/check-icon.svg') no-repeat center;
            background-size: contain;
          }
        }

				button {
					flex: 0 0 80px;
					max-height: 32px;
          min-height: 32px;
          height: 32px;
          line-height: 26px;

          &.delete {
            position: absolute;
            right: -44px;
            top: 0;
            margin: 0;
          }
				}

        span.level {
          background: $greylight;
          color: rgba($grey,0.5);
          font-weight: normal;
					flex: 0 0 96px;
          margin-right: 5px;
          text-align: left;
          border: 1px solid $greymedium;
          height: 32px;
          line-height: 16px;
          padding: 8px 12px;
        }
			}
		}
	}

	p.error {
		position: absolute;
		bottom: 2px;
		left: 0;
		width: 100%;
		color: $red;

		+ input {
			border-color: $red;
			background: rgba($red,0.15);
		}

		~ * {
			margin-bottom: 20px !important;
		}
	}

	select {
		font-family: $font-primary;
		flex: 0 0 96px;
		height: 32px;
		min-height: 32px;
		max-height: 32px;
		line-height: 32px;
		padding-top: 7px;
		padding-bottom: 7px;
		border-radius: 0;
	}
}