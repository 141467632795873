// Mixins SCSS File - SCSS v1.1
// last edited 3/14/17
// @author Justin Stollsteimer - Fusionbox, Inc.
// http://www.fusionbox.com

// Rem Calc
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

@function convert-to-rem($value, $base-value: $rem-base)  {
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;
  @if ($value == 0rem) { $value: 0; } // Turn 0rem into 0
  @return $value;
}

@function rem-calc($values, $base-value: $rem-base) {
  $max: length($values);
  @if $max == 1 { @return convert-to-rem(nth($values, 1), $base-value); }
  $remValues: ();
  @for $i from 1 through $max {
    $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
  }
  @return $remValues;
}

$testPrintStyles: false;

@mixin print {
  @if ($testPrintStyles) {
    @media all {
      @content;
    }
  }
  @else
  {
    @media print {
      @content;
    }
  }
}

// Media Queries
$small-width: 480px;
$medium-width: 720px;
$large-width: 1024px;
$xlarge-width: 1400px;

@mixin small {
  @media (min-width: #{$small-width}) {
    @content;
  }
}

@mixin medium {
  @media (min-width: #{$medium-width}) {
    @content;
  }
}

@mixin large {
  @media (min-width: #{$large-width}) {
    @content;
  }
}

@mixin xlarge {
  @media (min-width: #{$xlarge-width}) {
    @content;
  }
}

@mixin large-down {
  @media (max-width: #{$large-width}) {
    @content;
  }
}

@mixin medium-down {
  @media (max-width: #{$medium-width}) {
    @content;
  }
}

@mixin small-down {
  @media (max-width: #{$small-width}) {
    @content;
  }
}


@mixin box-sizing($what: border-box) {
  -webkit-box-sizing: $what;
  -moz-box-sizing: $what;
  box-sizing: $what;
}

@mixin inset-shadow($color: $black30, $left: 0px, $top: 3px, $blur: 5px) {
  box-shadow: inset $left $top $blur $color;
}

@mixin hgradient($left, $right) {
  background-color: $right;
  background-image: -o-linear-gradient(left, $left 1%, $right 100%);
  background-image: -ms-linear-gradient(left, $left 1%, $right 100%);
  background-image: -moz-linear-gradient(left, $left 1%, $right 100%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0.01, $left), color-stop(1.00, $right));
}

@mixin transition($basic: all 0.1s ease 0s) {
  -o-transition: #{$basic};
  -ms-transition: #{$basic};
  -moz-transition: #{$basic};
  -webkit-transition: #{$basic};
  transition: #{$basic};
}

@mixin clearfix {
  &:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }

  * html & {
    height: 1px
  }
}

@mixin bounce {
  animation-iteration-count: 1;
}

@mixin striped {
  background: $greylight url('../image/pattern-angles.png');
  background-size: 40px 40px;
  background-repeat: repeat;
}

@mixin inputDefault($min-height: $input-min-height-default, $color: $grey) {
  border: 1px solid $input-border-default;
  border-radius: 2px;
  color: $color;
  display: inline-block;
  font-size: 1.4rem;
  min-height: $min-height;
  line-height: 22px;
  font-family: $font-default;
  margin: 0px;
  padding: 8px;
  width: 100%;
  transition: border-color .07s ease-out;

  &:focus {
    outline: 0;
    border-color: $teal;
    background: white;
  }
}

@mixin input-silent {
  background: none;
  border: none;
}

@mixin chartTitle {
  color: black;
  line-height: 1.4;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 1px;
  position: relative;
  text-transform: uppercase;
  margin: 40px auto 20px auto;
}

@mixin sectionTitle {
  font-family: $font-primary;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .2px;
  margin-bottom: .4rem;
}
