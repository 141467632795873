// Chart Widget Shelf
.shelf {
  background: $greylight;
  position: relative;
  z-index: 2;

  > ul {
    opacity: 0;

    a {
      animation-name: fade-in;
      animation-direction: alternate;
      animation-duration: 300ms;
      transform-origin: 0 0;
    }
  }

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: $gold;
    transition: transform .2s ease-out;
  }

  &:before {
    top: -2px;
  }

  &:after {
    bottom: 0;
    z-index: 2;
  }

  &.isOpen {

    &:after {
      transform: translateY($shelf-height);
    }

    ul {
      opacity: 1;
      padding-left: 2rem;
    }

    ~ .playbookChart,
    ~ .shelf {
      transform: translateY($shelf-height);
    }

    a {
      transform: scale(1);
    }

    .close {
      background: $goldlight;
      bottom: -22px;

      &:before,
      &:after {
        background: darken($gold, 8%);
      }

      &:before {
        transform: rotate(135deg);
      }

      &:after {
        transform: rotate(45deg);
      }

      &:hover {
        border-color:  darken($gold, 10%);

        &:before,
        &:after {
          background: darken($gold, 10%);
        }
      }
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    transform: translateY(55px);
    list-style: none;
    max-width: $global-content-width;
    margin: auto;
    justify-content: flex-start;
    will-change: height;

    @include medium {
      max-height: 0;
    }

    li {
      flex: 0 0 100%;

      @include medium {
        flex: 0 1 25%;
      }

      a.chart {
        // Add chart buttons
        @include transition;
        background: transparent;
        display: block;
        border: 0;
        border-radius: 3px;
        cursor: pointer;
        color: lighten($grey, 15%);
        font-size: 12px;
        line-height: 16px;
        font-family: $font-primary;
        font-weight: 600;
        text-align: left;
        padding: 5px 10px;

        span.image {
          box-sizing: content-box;
          background-image: url('http://www.placehold.it/60x60');
          background-position: center;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          border: 3px solid transparent;
          border-radius: 50%;
          display: inline;
          float: left;
          width: 30px;
          height: 30px;
          margin-right: 5px;
          overflow: hidden;
          position: relative;
          top: -10px;
        }

        &:hover,
        &:focus {
          box-shadow: 0 0 0 $black;
          background: white;
          color: $grey;

          span.image {
            border-color: white;
          }
        }

        &.CoreValuesStratop,
        &.CoreValuesLifeplan {
          .image {
            background-image: url('../../image/icon-core-values.svg');
          }
        }

        &.FountainOfYouth {
          .image {
            background-image: url('../../image/icon-fountain-of-youth.svg');
          }
        }

        &.FourHelpfulLists,
        &.FourHelpfulListsStratop {
          .image {
            background-image: url('../../image/icon-four-helpful-lists.svg');
          }
        }

        &.FourHelpfulListsMatrix {
          .image {
            background-image: url('../../image/icon-four-helpful-lists-matrix.svg');
          }
        }

        &.GenericTable {
          .image {
            background-image: url('../../image/icon-generic-table.svg');
          }
        }

        &.InternalWiring {
          .image {
            background-image: url('../../image/icon-internal-wiring.svg');
          }
        }

        &.OpportunityMap,
        &.LifePlanLearnings,
        &.ActionInitiativePlan {
          .image {
            background-image: url('../../image/icon-lifeplan-learnings.svg');
          }
        }

        &.ActionInitiativesLongList {
          .image {
            background-image: url('../../image/icon-long-list.svg');
          }
        }

        &.MyLifeDashboard,
        &.StrategicDashboard {
          .image {
            background-image: url('../../image/icon-my-life-dashboard.svg');
          }
        }

        &.LifeInitiativesProfile,
        &.ActionInitiativesProfile {
          .image {
            background-image: url('../../image/icon-life-initiatives-profile.svg');
          }
        }

        &.LifePlanAccountability {
          .image {
            background-image: url('../../image/icon-lifeplan-accountability.svg');
          }
        }

        &.LifePlanObjectives {
          .image {
            background-image: url('../../image/icon-lifeplan-objectives.svg');
          }
        }

        &.LifeCycleAnalysis {
          .image {
            background-image: url('../../image/icon-life-cycle-analysis.svg');
          }
        }

        &.LifePerspectiveFilter {
          .image {
            background-image: url('../../image/icon-life-perspective-filter.svg');
          }
        }


        &.OpportunityRiskAnalysis {
          .image {
            background-image: url('../../image/icon-opportunity-risk-analysis.svg');
          }
        }

        &.ReplenishmentCycle,
        &.ValueBuildingCycle {
          .image {
            background-image: url('../../image/icon-replenishment-cycle.svg');
          }
        }

        &.TalentHeartAssessment {
          .image {
            background-image: url('../../image/icon-talent-heart.svg');
          }
        }

        &.ThinkingWavelengthLifeplan,
        &.ThinkingWavelengthStratop {
          .image {
            background-image: url('../../image/icon-thinking-wavelength.svg');
          }
        }

        &.TimeAssessment {
          .image {
            background-image: url('../../image/icon-time-assessment.svg');
          }
        }

        &.TurningPoints {
          .image {
            background-image: url('../../image/icon-turning-points-stratop.svg');
          }
        }

        &.TurningPointLearningsLifeplan,
        &.TurningPointLearningsStratop {
          .image {
            background-image: url('../../image/icon-turning-point-learnings.svg');
          }
        }

        &.TurningPointProfile,
        &.TurningPointProfileStratop {
          .image {
            background-image: url('../../image/icon-turning-point-profile.svg');
          }
        }

        &.VocationalGating {
          .image {
            background-image: url('../../image/icon-vocational-gating-process.svg');
          }
        }

        &.OurPrimaryCustomer,
        &.OurPrimaryCustomersValues {
          .image {
            background-image: url('../../image/icon-funnel.svg');
          }
        }

        &.WhatIsOurBusiness {
          .image {
            background-image: url('../../image/icon-what-is-our-business.svg');
          }
        }

        &.PatternsTrendsAnalysis,
        &.ExternalPatternsAndTrends,
        &.InternalPatternsAndTrends {
          .image {
            background-image: url('../../image/icon-internal-patterns-and-trends.svg');
          }
        }

        &.MarketSaturation {
          .image {
            background-image: url('../../image/icon-market-saturation.svg');
          }
        }

        &.CoreAssumptions {
          .image {
            background-image: url('../../image/icon-core-assumptions.svg');
          }
        }

        &.OurMission {
          .image {
            background-image: url('../../image/icon-mission.svg');
          }
        }

        &.OurVision {
          .image {
            background-image: url('../../image/icon-vision.svg');
          }
        }

        &.OurBigIdeaCoreStrategies {
          .image {
            background-image: url('../../image/icon-core-strategies.svg');
          }
        }

        &.SituationalAnalysis {
          .image {
            background-image: url('../../image/icon-situational-analysis.svg');
          }
        }
      }
    }
  }

  .close, .open {
    transition: all 0.25s ease 0s;
    border: 2px solid $gold;
    border-radius: 50%;
    position: absolute;
    top: -21px;
    background: white;
    left: calc(50% - 22px);
    display: block;
    cursor: pointer;
    width: 44px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    z-index: 100;

    &:before,
    &:after {
      transition: all 0.25s ease 0s;
      content: '';
      display: block;
      width: 24px;
      height: 2px;
      background: $gold;
      position: absolute;
      left: calc(50% - 12px);
      top: calc(50% - 1px);
    }

    &:before {
      transform: rotate(90deg);
    }

    &:hover {
      background: mix($gold, $white, 25%);
    }
  }

  // StratOp Specific styles
  .editor.stratop &.isOpen {
    // Override shelf heights to account for different number of chart icons
     ~ .playbookChart,
     ~ .shelf {
      transform: translateY($shelf-height-stratop);
    }

    &:after {
      transform: translateY($shelf-height-stratop);
    }
  }


  &:last-child {
    .shelf {
      border-bottom: 0;
    }
  }
}
