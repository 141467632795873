@import "../../css/_variables";
@import "../../css/_mixins";
@import "TableOverflowWrapper";

.lifeInitiativesProfile,
.actionInitiativesProfile {
  page-break-before: avoid;

  .chartCaption.chartCaptionEditable {
    margin-bottom: 0;
  }

  div.tableOverflowWrapper {
    padding-top: 80px;
  }

  div.tableWrap  {
    display: block;
    width: 100%;
    page-break-after: always;
    page-break-before: avoid;
  }

  table {
    table-layout: auto;
    page-break-inside: auto;
  }

  table th {
    font-family: $font-primary;
    font-weight: 600;
    width: calc(100% / 6);
    text-align: center;

    span.by, span.leader {
      display: none; // POAP Only
    }
  }

  table td:last-of-type {
    text-align: center;
  }

  table td {
    border-bottom: 2px solid $gold;
    background: none;
    padding: 12px 0 10px 0;
    vertical-align: middle;

    .editor & {
      vertical-align: top;
    }

    span.leader {
      display: none; // POAP Only
    }
  }

  .editor & table td {
    padding: 12px 0 0 0;
  }

  table td input {
    width: 100%;
  }

  table td div {
    display: flex;
    align-items: flex-start;

    ~ div {
      margin-top: 4px;
    }
  }

  tr.add td {
    padding: 0 12px;
  }

  tr.add td button,
  tr.add td button:hover {
    margin-top: .8rem;
    margin-bottom: .8rem;
  }

  .delete {
    max-width: 3.5rem;
    max-height: 4rem;
    position: relative;
    margin-right: 4px;
    flex: 0 0 35px;
  }

  button.add {
    display: block;
    width: 100%;
    border-color: transparent;
  }

  span {
    vertical-align: baseline;
  }

  span.step {
    flex: 1 1 auto;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: .75rem;
  }

  .editor & span.step {
    flex: 1 1 auto;
  }

  span.blank {
    display: inline-block;
    padding-left: .5em;
    vertical-align: baseline;
  }

  span.step {
    padding: 0;
    border: 0;
    margin-right: 8px;
    flex: 1 1 auto;
  }

  span.deliverable {
    margin-right: 8px;
  }

  span.date {
    margin-left: 0;
    margin-right: 0;
    border: 0;
    flex: 1 1 auto;

    .viewer & {
      flex: 1 1 auto;
    }
  }

  tr > td.domain,
  tr > td.coreIssue {
    text-align: center;
    border-style: solid;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
    padding: 12px 10px 10px;

    span {
      color: white !important;
      font-family: $font-primary;
      font-weight: 600;
    }
  }

  .editor & tr > td:first-of-type {
    text-align: left;
  }

  td > .statusAndTrendIndicator span {
    border: 0;
    background-size: contain;
    background-repeat: no-repeat;
  }

  td > .statusAndTrendIndicator {
    position: relative;
    margin: auto;
    transform-origin: 50% 50%;
  }

  .editor & td > .statusAndTrendIndicator {
    min-width: 0;
  }

  .editor & {
    .deliverable {
      flex: 0 1 115px;
      margin-bottom: 1rem;
      margin-right: .8rem;
    }
  }

  .deliverableWrap {
    > span:first-of-type {
      flex-basis: 160px;
      padding-right: 1rem;
    }
  }

  th.initiativesProfileStart > input,
  th.initiativesProfileStart > span {
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
  }

  th.initiativesProfileGroup {
    width: 125px;
  }

  th.initiativesProfileNext {
    width: 150px;

    span.startDate {
      display: none;
    }
  }
}

@include medium-down {
  @media screen {
    .viewer {
      .tableWrap {
        margin-bottom: 50px;
      }

      .lifeInitiativesProfile,
      .actionInitiativesProfile {
        thead {
          display: none;
        }

        tr, td, th {
          display: block;
        }

        tr:nth-child(even) {
          background: rgba($gold,0.3);
        }

        td.domain {
          border: 0;
        }

        td {
          padding: 10px;

          div.statusIndicator {
            align-items: center;
          }
        }

        td:before {
          font-weight: bold;
          color: black;
          display: block;
          margin-bottom: 5px;
        }

        td.initiative:before { content: "INITIATIVE"; }
        td.objective:before { content: "OBJECTIVE"; }
        td.steps:before { content: "NEXT STEPS"; width: 100%; }
        td.status:before { content: "STATUS"; }
      }
    }
  }
}

.editor .playbookChart .chart.lifeInitiativesProfile,
.editor .playbookChart .chart.actionInitiativesProfile {
  max-width: 100%;
}
