@import "../../css/_variables";
@import "../../css/_mixins";

/* Structure
----------------------------------------------------------------*/
.fountainOfYouth div.dataWrapper {
  position: relative;
}

.fountainOfYouth div.columns {
  display: flex;
  width: calc(100% + 8px);
  position: relative;
  left: -4px;
  z-index: 4;
}

.fountainOfYouth div.columns > div {
  background-color: #EBE9E1;
  margin: 1px;
  min-width: 25%;
  max-width: 25%;
}

.fountainOfYouth div.columns.label > div {
  background: white;
  margin: 1px;
  min-width: calc(50% + 2px);
  max-width: calc(50% + 2px);
}

.fountainOfYouth img.path {
  position: absolute;
  width: calc(100% + 14px);
  height: 90%;
  top: 5%;
  left: -3px;
  z-index: 10;
  display: none;
}

/* Textarea / Fields
----------------------------------------------------------------*/

.fountainOfYouth div.columns .textWrap {
  display: block;
  min-height: 400px;
}

.editor .fountainOfYouth div.columns .column {
  display: flex;
}

.editor .fountainOfYouth div.columns .column .textWrap {
  flex: 1 1 auto;
  display: flex;
  cursor: text;
  position: relative;
}

.editor .fountainOfYouth div.columns .textWrap span,
.editor .fountainOfYouth div.columns .textWrap textarea {
  flex: 1 1 auto;
}

.fountainOfYouth div.columns .textWrap > div {
  display: block;
  padding: 8px;
  background: rgba(235,233,225,0.8);
  position: relative;
  white-space: pre-wrap;
  width: 100%;
  z-index: 100;
}

.fountainOfYouth div.columns .textWrap > div p {
  margin-bottom: 8px;
}

.fountainOfYouth div.columns .textWrap > textarea {
  display: block;
  height: auto;
  min-height: 0;
  max-height: none;
  padding: 8px;
  position: relative;
  z-index: 100;
}

.fountainOfYouth div.columns .textWrap span.blank {
  font-style: italic;
  color: rgba(0,0,0,0.5);
}

.editor .fountainOfYouth div.columns .textWrap > div:hover {
  background: rgba(255,255,255,0.8);
  color: $grey;
}

.viewer .fountainOfYouth div.columns .textWrap > div:empty {
  padding: 0;
}

/* Column Titles
----------------------------------------------------------------*/

.fountainOfYouth div.columns h2 {
  background: white;
  font-family: $font-primary;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 2px solid white;
  padding: 16px 0;
}

.fountainOfYouth div.columns h2 img {
  display: none;
}

.fountainOfYouth div.columns > div:nth-child(-n+2) h2 {
  background: $greylightwarm;
}

.fountainOfYouth div.columns.data h2 {
  display: none;
}


@include small {

  /* Column Color Accents (Desktop)
  ----------------------------------------------------------------*/

  .fountainOfYouth div.columns.data > div:nth-child(1) {
    border: 2px solid $green;
    border-width: 2px 0;
    background-image: url('../../image/foy-path.svg');
    background-size: 403% 95%;
    background-position: 0% 50%;
    background-repeat: repeat-x;
  }

  .fountainOfYouth div.columns.titles > div:nth-child(1) {
    border: 2px solid $green;
    border-width: 0 0 2px 0;
  }

  .fountainOfYouth div.columns.data > div:nth-child(2) {
    border: 2px solid $blue;
    border-width: 2px 0;
    background-image: url('../../image/foy-path.svg');
    background-size: 403% 95%;
    background-position: -100% 50%;
    background-repeat: repeat-x;
  }

  .fountainOfYouth div.columns.titles > div:nth-child(2) {
    border: 2px solid $blue;
    border-width: 0 0 2px 0;
  }

  .fountainOfYouth div.columns.data > div:nth-child(3) {
    border: 2px solid #8E0F58;
    border-width: 2px 0;
    background-image: url('../../image/foy-path.svg');
    background-size: 401% 95%;
    background-position: -200% 50%;
    background-repeat: repeat-x;
  }

  .fountainOfYouth div.columns.titles > div:nth-child(3) {
    border: 2px solid #8E0F58;
    border-width: 0 0 2px 0;
  }

  .fountainOfYouth div.columns.data > div:nth-child(4) {
    border: 2px solid $orange;
    border-width: 2px 0;
    background-image: url('../../image/foy-path.svg');
    background-size: 400% 95%;
    background-position: -300% 50%;
    background-repeat: repeat-x;
  }

  .fountainOfYouth div.columns.titles > div:nth-child(4) {
    border: 2px solid $orange;
    border-width: 0 0 2px 0;
  }

  /* FOY Label (Desktop)
  ----------------------------------------------------------------*/

  .fountainOfYouth div.columns.label h1 {
    border-bottom: 2px solid $gold;
    text-align: center;
    line-height: 50px;
    font-size: 20px;
    font-family: $font-primary;
    font-weight: 600;
    position: relative;
  }
}

/* Responsive/Mobile
----------------------------------------------------------------*/

@include small-down {

  .fountainOfYouth div.columns.titles,
  .fountainOfYouth div.columns.label {
    display: none;
  }

  .fountainOfYouth div.columns.data {
    display: block;
  }

  .fountainOfYouth div.columns.data:after {
     visibility: hidden;
     display: block;
     font-size: 0;
     content: " ";
     clear: both;
     height: 0;
  }

  .fountainOfYouth div.columns.data > div {
    float: left;
    min-width: calc(50% - 2px);
    max-width: calc(50% - 2px);
    margin-bottom: 25px;
  }

  .fountainOfYouth div.columns.data > div:nth-of-type(3) {
    clear: left;
  }

  .fountainOfYouth div.columns.data h2 {
    display: block;
    border-bottom: 2px solid $gold;
    margin-bottom: 55px;
    position: relative;
  }

  .fountainOfYouth div.columns.data h2 img {
    position: absolute;
    top: 55px;
    left: 0;
    display: block;
    width: 100%;
    height: 95%;
  }

  .fountainOfYouth div.columns.data h2:before {
    content: "";
    background: rgba(204,174,83,0.7);
    height: 54px;
    width: 25%;
    position: absolute;
    top: 53px;
  }

  .fountainOfYouth div.columns.data > div:nth-child(1) h2:before { left: 0; }
  .fountainOfYouth div.columns.data > div:nth-child(2) h2:before { left: 25%; }
  .fountainOfYouth div.columns.data > div:nth-child(3) h2:before { right: 25%; }
  .fountainOfYouth div.columns.data > div:nth-child(4) h2:before { right: 0; }

  .fountainOfYouth div.columns .textWrap {
    border-top: 2px solid $gold;
    position: relative;
    top: -2px;
  }

  .fountainOfYouth div.columns .textWrap > div {
    background: white;
  }

  .fountainOfYouth div.columns .textWrap > div p:last-child {
    margin-bottom: 0;
  }

  .fountainOfYouth div.columns .textWrap,
  .fountainOfYouth div.columns .textWrap > textarea {
    min-height: 0px;
    height: auto;
  }

  .fountainOfYouth div.columns > div:nth-child(-n+2) h2 {
    background: white;
  }

  .fountainOfYouth div.columns > div:nth-child(-n+2) .textWrap:before {
    content: "";
    height: 22px;
    line-height: 22px;
    width: 100%;
    display: block;
    position: relative;
    top: -2px;
  }

  .fountainOfYouth div.columns > div:nth-child(1) .textWrap:before {
    content: "FOUNTAIN OF YOUTH";
    width: calc(200% + 2px);
    text-align: center;
    letter-spacing: 2px;
    font-weight: 700;
    background: $gold;
    z-index: 10;
  }
}